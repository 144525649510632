/* src/app/pages/home/home.component.scss */
.m-0,
[m-0] {
  margin: 0;
}
.mt-0,
.my-0,
[mt-0],
[my-0] {
  margin-top: 0;
}
.mr-0,
.mx-0,
[mr-0],
[mx-0] {
  margin-right: 0;
}
.mb-0,
.my-0,
[mb-0],
[my-0] {
  margin-bottom: 0;
}
.ml-0,
.mx-0,
[ml-0],
[mx-0] {
  margin-left: 0;
}
.m-1,
[m-1] {
  margin: 0.25rem;
}
.mt-1,
.my-1,
[mt-1],
[my-1] {
  margin-top: 0.25rem;
}
.mr-1,
.mx-1,
[mr-1],
[mx-1] {
  margin-right: 0.25rem;
}
.mb-1,
.my-1,
[mb-1],
[my-1] {
  margin-bottom: 0.25rem;
}
.ml-1,
.mx-1,
[ml-1],
[mx-1] {
  margin-left: 0.25rem;
}
.m-2,
[m-2] {
  margin: 0.5rem;
}
.mt-2,
.my-2,
[mt-2],
[my-2] {
  margin-top: 0.5rem;
}
.mr-2,
.mx-2,
[mr-2],
[mx-2] {
  margin-right: 0.5rem;
}
.mb-2,
.my-2,
[mb-2],
[my-2] {
  margin-bottom: 0.5rem;
}
.ml-2,
.mx-2,
[ml-2],
[mx-2] {
  margin-left: 0.5rem;
}
.m-3,
.page .items,
:host .items,
[m-3] {
  margin: 1rem;
}
.mt-3,
.my-3,
[mt-3],
[my-3] {
  margin-top: 1rem;
}
.mr-3,
.mx-3,
[mr-3],
[mx-3] {
  margin-right: 1rem;
}
.mb-3,
.my-3,
[mb-3],
[my-3] {
  margin-bottom: 1rem;
}
.ml-3,
.mx-3,
[ml-3],
[mx-3] {
  margin-left: 1rem;
}
.m-4,
[m-4] {
  margin: 1.5rem;
}
.mt-4,
.my-4,
[mt-4],
[my-4] {
  margin-top: 1.5rem;
}
.mr-4,
.mx-4,
[mr-4],
[mx-4] {
  margin-right: 1.5rem;
}
.mb-4,
.my-4,
[mb-4],
[my-4] {
  margin-bottom: 1.5rem;
}
.ml-4,
.mx-4,
[ml-4],
[mx-4] {
  margin-left: 1.5rem;
}
.m-5,
[m-5] {
  margin: 3rem;
}
.mt-5,
.my-5,
[mt-5],
[my-5] {
  margin-top: 3rem;
}
.mr-5,
.mx-5,
[mr-5],
[mx-5] {
  margin-right: 3rem;
}
.mb-5,
.my-5,
[mb-5],
[my-5] {
  margin-bottom: 3rem;
}
.ml-5,
.mx-5,
[ml-5],
[mx-5] {
  margin-left: 3rem;
}
.m-6,
[m-6] {
  margin: 4rem;
}
.mt-6,
.my-6,
[mt-6],
[my-6] {
  margin-top: 4rem;
}
.mr-6,
.mx-6,
[mr-6],
[mx-6] {
  margin-right: 4rem;
}
.mb-6,
.my-6,
[mb-6],
[my-6] {
  margin-bottom: 4rem;
}
.ml-6,
.mx-6,
[ml-6],
[mx-6] {
  margin-left: 4rem;
}
.p-0,
[p-0] {
  padding: 0;
}
.pt-0,
.py-0,
[pt-0],
[py-0] {
  padding-top: 0;
}
.pr-0,
.px-0,
[pr-0],
[px-0] {
  padding-right: 0;
}
.pb-0,
.py-0,
[pb-0],
[py-0] {
  padding-bottom: 0;
}
.pl-0,
.px-0,
[pl-0],
[px-0] {
  padding-left: 0;
}
.p-1,
[p-1] {
  padding: 0.25rem;
}
.pt-1,
.py-1,
[pt-1],
[py-1] {
  padding-top: 0.25rem;
}
.pr-1,
.px-1,
[pr-1],
[px-1] {
  padding-right: 0.25rem;
}
.pb-1,
.py-1,
[pb-1],
[py-1] {
  padding-bottom: 0.25rem;
}
.pl-1,
.px-1,
[pl-1],
[px-1] {
  padding-left: 0.25rem;
}
.p-2,
[p-2] {
  padding: 0.5rem;
}
.pt-2,
.py-2,
[pt-2],
[py-2] {
  padding-top: 0.5rem;
}
.pr-2,
.px-2,
[pr-2],
[px-2] {
  padding-right: 0.5rem;
}
.pb-2,
.py-2,
[pb-2],
[py-2] {
  padding-bottom: 0.5rem;
}
.pl-2,
.px-2,
[pl-2],
[px-2] {
  padding-left: 0.5rem;
}
.p-3,
[p-3] {
  padding: 1rem;
}
.pt-3,
.py-3,
[pt-3],
[py-3] {
  padding-top: 1rem;
}
.pr-3,
.px-3,
[pr-3],
[px-3] {
  padding-right: 1rem;
}
.pb-3,
.py-3,
[pb-3],
[py-3] {
  padding-bottom: 1rem;
}
.pl-3,
.px-3,
[pl-3],
[px-3] {
  padding-left: 1rem;
}
.p-4,
[p-4] {
  padding: 1.5rem;
}
.pt-4,
.py-4,
[pt-4],
[py-4] {
  padding-top: 1.5rem;
}
.pr-4,
.px-4,
[pr-4],
[px-4] {
  padding-right: 1.5rem;
}
.pb-4,
.py-4,
[pb-4],
[py-4] {
  padding-bottom: 1.5rem;
}
.pl-4,
.px-4,
[pl-4],
[px-4] {
  padding-left: 1.5rem;
}
.p-5,
[p-5] {
  padding: 3rem;
}
.pt-5,
.py-5,
[pt-5],
[py-5] {
  padding-top: 3rem;
}
.pr-5,
.px-5,
[pr-5],
[px-5] {
  padding-right: 3rem;
}
.pb-5,
.py-5,
[pb-5],
[py-5] {
  padding-bottom: 3rem;
}
.pl-5,
.px-5,
[pl-5],
[px-5] {
  padding-left: 3rem;
}
.p-6,
[p-6] {
  padding: 4rem;
}
.pt-6,
.py-6,
[pt-6],
[py-6] {
  padding-top: 4rem;
}
.pr-6,
.px-6,
[pr-6],
[px-6] {
  padding-right: 4rem;
}
.pb-6,
.py-6,
[pb-6],
[py-6] {
  padding-bottom: 4rem;
}
.pl-6,
.px-6,
[pl-6],
[px-6] {
  padding-left: 4rem;
}
.m-auto,
[m-auto] {
  margin: auto;
}
.mt-auto,
.my-auto,
[mt-auto],
[my-auto] {
  margin-top: auto;
}
.mr-auto,
.mx-auto,
[mr-auto],
[mx-auto] {
  margin-right: auto;
}
.mb-auto,
.my-auto,
[mb-auto],
[my-auto] {
  margin-bottom: auto;
}
.ml-auto,
.mx-auto,
[ml-auto],
[mx-auto] {
  margin-left: auto;
}
.gap-0,
[gap-0] {
  gap: 0;
}
.gap-1,
[gap-1] {
  gap: 0.25rem;
}
.gap-2,
[gap-2] {
  gap: 0.5rem;
}
.gap-3,
.page .items,
:host .items,
[gap-3] {
  gap: 1rem;
}
.gap-4,
[gap-4] {
  gap: 1.5rem;
}
.gap-5,
[gap-5] {
  gap: 3rem;
}
.gap-6,
[gap-6] {
  gap: 4rem;
}
.page,
:host {
  overflow-y: hidden;
  width: 100vw;
  flex: 1;
}
.page .control.exit,
:host .control.exit {
  position: absolute;
  right: 1vh;
  bottom: 1vh;
  z-index: 99;
}
.page .items,
:host .items {
  display: grid;
  overflow-y: auto;
}
@media (min-width: 0) and (max-width: 599.9px) {
  .page .items,
  :host .items {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 600px) and (max-width: 959.9px) {
  .page .items,
  :host .items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 960px) and (max-width: 1279.9px) {
  .page .items,
  :host .items {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1280px) and (max-width: 1919.9px) {
  .page .items,
  :host .items {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1920px) and (max-width: 4999.99px) {
  .page .items,
  :host .items {
    grid-template-columns: repeat(4, 1fr);
  }
}
.page .code,
:host .code {
  font-family: monospace;
  font-size: 12px;
}
.page .in-option-image,
:host .in-option-image {
  height: 32px;
}
.page .preview-image,
:host .preview-image {
  display: block;
  width: max-content;
  margin-bottom: 10px;
}
page-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
/*# sourceMappingURL=home.component.css.map */
