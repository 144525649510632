<span *ngIf='!editMode'
      (click)='editMode=enabled&&true'
      [class.enabled]="enabled"
      class='cell'>{{ data }}</span>

<mat-form-field style="width:100%" *ngIf='editMode'>
  <input matInput
         (keydown.enter)='onConfirmButtonClick(inputField.value)'
         [value]='data'
         appAutofocus
         #inputField
         [type]='type'>
  <button matSuffix mat-icon-button (click)="editMode=false">
    <i class="la la-times la-fw"></i>
  </button>
  <button matSuffix mat-icon-button (click)="onConfirmButtonClick(inputField.value)">
    <i class="la la-check la-fw"></i>
  </button>
</mat-form-field>
