<app-library-explorer2-layout (scrolledToBottom)="onScrolledToBottom()"
                              [backgroundImage]="profileSettingsService.showLibraryBackgroundImage"
                              [(searchText)]="stateService.containerItemsSearchText"
                              [availableBasePaths]="stateService.basePaths"
                              [(basePath)]="stateService.currentBasePath"
                              [availableViews]="stateService.containerItemsViews"
                              [(views)]="stateService.containerItemsSelectedViews"
                              [availableInitials]="stateService.containerItemsInitials"
                              [(initial)]="stateService.containerItemsSearchText"
                              exitRoute="/home">

  <page-title>{{'ui.shows'|translate}}</page-title>

  <page-content>

    <div class="" *ngIf="stateService.containerItems.length==0">
      <h1>{{'ui.nothingToDisplay'|translate}}</h1>
    </div>

    <div class="items">

      <app-item-layout *ngFor="let item of stateService.containerItems" [layout]="['card']" [item]="item">

        <item-primary-actions>
          <button mat-button color="accent" [routerLink]="['/show-library/shows', item.id]">
            <i class="la la-folder-open la-fw"></i>  {{'actions.open'|translate}}
          </button>
        </item-primary-actions>

        <item-secondary-actions>
          <button mat-button type="button" (click)="onMarkAsButtonClick(item.id, item.progressPercent < 0.99)">
            <i class="la la-check la-fw"></i> {{'actions.markAs'|translate}}
            {{(item.progressPercent >=0.99 ? 'ui.notSeen' : 'ui.seen')|translate}}
          </button>
          <button mat-button type="button" (click)="onForceInspectButtonClick(item.id)">
            <i class="la la-sync la-fw"></i>  {{'actions.inspect'|translate}}
          </button>

          <mat-form-field>
            <mat-label>{{'actions.publishTo'|translate}}</mat-label>
            <mat-select multiple [compareWith]="compareToDevices" [ngModel]="item.toDevices">
              <mat-option (onSelectionChange)="onToDeviceSelectionChange($event,item.id)" *ngFor="let device of profileSettingsService.profile.devices;" [value]="device.id">{{device.name}} ({{device.pushMethod}})</mat-option>
            </mat-select>
          </mat-form-field>

        </item-secondary-actions>

      </app-item-layout>

    </div>

  </page-content>

</app-library-explorer2-layout>
