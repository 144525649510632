<div style="display:flex; justify-content: center; align-content:center" class="black-theme">
  <div style="height: 80vh;display:flex; flex-direction: column; justify-content: center; align-content:center">
    <mat-card appearance="outlined" >
      <mat-card-content>
        <div>
          <mat-autocomplete #serverUrlAutocomplete="matAutocomplete" (optionSelected)="onServerUrlAutocompleteOptionSelected($event)">
            <mat-option *ngFor="let option of globalStateService.serverUrls" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          <mat-form-field>
            <mat-label>{{'ui.serverUrl'|translate}}</mat-label>
            <input matInput type="url" name="serverUrl" [(ngModel)]="globalStateService.serverUrl"
                   placeholder="{{'ui.serverUrl'|translate}}" [matAutocomplete]="serverUrlAutocomplete">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>{{'ui.userName'|translate}}</mat-label>
            <input matInput [(ngModel)]="username" placeholder="{{'ui.userName'|translate}}">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>{{'ui.password'|translate}}</mat-label>
            <input matInput [(ngModel)]="password" [type]="showPassword ? 'text' : 'password'" placeholder="{{'ui.password'|translate}}">
          </mat-form-field>
          <button type="button" (click)="showPassword = !showPassword" mat-icon-button>
            <i class="la {{showPassword ? 'la-eye-slash' : 'la-eye'}} la-fw"></i>
          </button>
        </div>
        <!--<div *ngIf="!!username && !!password && (!this.globalStateService.apiKeys[this.globalStateService.serverUrl] || this.globalStateService.apiKeys[this.globalStateService.serverUrl].username != username)" >
          <mat-slide-toggle [(ngModel)]="trustDevice">
            {{'ui.enableAutoLogin'|translate}}
          </mat-slide-toggle>
        </div>-->
      </mat-card-content>
      <mat-card-actions  style="display:flex; flex-direction: column; justify-content: center; align-content:center;gap: 6px;">
        <button mat-raised-button color="primary" (click)="tryLocalLogin()">
          <i class="la la-user-circle la-fw"></i> {{'actions.login'|translate}}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
