<div class="layout full-vh">

  <router-outlet></router-outlet>

  <app-music-player></app-music-player>

  <div [class.hidden]="!this.loadingDetectionService.isLoading" class="progress">

    <mat-progress-bar color="accent" mode="indeterminate">
    </mat-progress-bar>

  </div>

</div>

