import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

window["alessandria-psk"] = "cNFuy63zfnmKUDq2CpH1GbQI8Z4Z0J0Vj9JYDeBO7Wj19XZY1PSAMgfkoF9kGY2p";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
