<mat-drawer-container class="full-vh">

  <mat-drawer #drawer [position]="'start'" [mode]="'push'">

    <mat-nav-list>
      <a *ngFor="let bookPageNumber of this.bookPageNumbers" mat-list-item routerLinkActive="active-link"
        (click)="onJumpToPageButtonClick(bookPageNumber)">
        {{'ui.page'|translate}} {{bookPageNumber}}
      </a>
    </mat-nav-list>

  </mat-drawer>

  <mat-drawer-content #drawerContent>

    <div class="viewer-root full-vh">

      <div #pageContentWrapper scrollEvent (onscroll)="onPageContentWrapperScroll($event)" [bottomOffset]="200"
        [topOffset]="200" class="page-content-wrapper full-vh">
        <div #pageContent class="page-content"></div>
      </div>

      <div class="page-bookmarks"></div>

      <button *ngIf="pageNumber>1" type="button" mat-icon-button class="control primary previous-page" #previousPage
        (click)="onJumpToPageButtonClick(pageNumber-1)">
        <i class="la la-chevron-left la-fw" ></i>
      </button>

      <button *ngIf="pageNumber<bookLength" type="button" mat-icon-button class="control primary next-page" #nextPage
        (click)="onJumpToPageButtonClick(pageNumber+1)">
        <i class="la la-chevron-right la-fw" ></i>
      </button>

      <button (click)="drawer.toggle()" *ngIf="bookLength" type="button" mat-flat-button class="control current-page"
        >{{pageNumber}} / {{bookLength}}</button>

      <a mat-icon-button [routerLink]="['/book-library']" class="control exit">
        <i class="la la-sign-out-alt la-fw" ></i>
      </a>

    </div>

  </mat-drawer-content>

</mat-drawer-container>
