<app-library-explorer2-layout (scrolledToBottom)="onScrolledToBottom()"
                              [backgroundImage]="profileSettingsService.comicLibraryBackgroundImage"
                              [(searchText)]="stateService.contentItemsSearchText"
                              [availableBasePaths]="stateService.basePaths"
                              [(basePath)]="stateService.currentBasePath"
                              [availableViews]="stateService.contentItemsViews"
                              [(views)]="stateService.contentItemsSelectedViews"
                              [availableInitials]="stateService.contentItemsInitials"
                              [(initial)]="stateService.contentItemsSearchText"
                              exitRoute="/comic-library">

  <page-title>{{stateService.parent?.title}}</page-title>

  <page-content>

    <div class="" *ngIf="stateService.contentItems.length==0">
      <h1>{{'ui.nothingToDisplay'|translate}}</h1>
    </div>

    <div class="items">

      <app-item-layout *ngFor="let item of stateService.contentItems"
                       [layout]="stateService.currentDisplayMode"
                       [item]="item"
                       [enabledEditables]="[ ((item.title || item.proposedSeriesTitle) ? 'title' : ''), 'number', 'year']"
                       (editableUpdated)="onEditableUpdated(item.id, $event)">

        <item-primary-actions>
          <button *ngIf="!stateService.parent.blackHole" mat-button color="accent"
                  [routerLink]="['/comics/', item.parent.id, item.id]">
            <i class="la la-play la-fw"></i> {{'actions.read'|translate}}
          </button>
        </item-primary-actions>

        <item-secondary-actions>
          <button *ngIf="!stateService.parent.blackHole" mat-button type="button"
                  (click)="onMarkAsButtonClick(item.id, item.progressPercent < 0.99)">
            <i class="la la-check la-fw"></i> {{'actions.markAs'|translate}}
            {{(item.progressPercent >=0.99 ? 'ui.notRead' : 'ui.read')|translate}}
          </button>
          <button mat-button color="warn" type="button" (click)="onRemoveButtonClick(item.id)">
            <i class="la la-trash la-fw"></i> {{'actions.remove'|translate}}
          </button>
          <div class="reorg-proposal" *ngIf="item.potentiallyMisplaced">
            <span>{{'ui.thisItemSeemsMisplaced'|translate}}.</span>
            <span>
              {{'ui.suggestedAction'|translate}}:
              <span [ngSwitch]="item.reorgMode">
                <span *ngSwitchCase="'CREATENEWSERIES'">
                  {{'ui.moveToNewSeries'|translate}}
                  <b>"{{item.proposedSeriesTitle}}"</b> {{'ui.as'|translate}} <b>"{{item.proposedFileName}}"</b>
                </span>
                <span *ngSwitchCase="'MOVETOEXISTINGSERIES'">
                  {{'ui.moveToExistingSeries'|translate}}
                  <b>"{{item.proposedSeriesTitle}}"</b> {{'ui.as'|translate}} <b>"{{item.proposedFileName}}"</b>
                </span>
                <span *ngSwitchCase="'RENAME'">{{'ui.renameFile'|translate}} <b>"{{item.proposedFileName}}"</b></span>
              </span>
            </span>
            <button mat-button color="accent" (click)="onReorgIssueButtonClick(item.id)">
              <i class="la la-check-circle la-fw"></i>
              {{'actions.apply'|translate}}
            </button>
          </div>
        </item-secondary-actions>

      </app-item-layout>

    </div>

  </page-content>

</app-library-explorer2-layout>

