<app-main-layout [backgroundImage]="profileSettingsService.defaultBackgroundImage">

  <page-title>Home</page-title>

  <page-content>

    <div class="items">

      <app-library-stats-layout *ngIf="statistics?.books?.recent.length>0" [layout]="['card']" [title]="'ui.books' | translate"
                                [subtitle]="'ui.booksStatistics' | translate : statistics.books"
                                [recent]="statistics.books.recent"
                                [routeBase]="'/books'">
      </app-library-stats-layout>

      <app-library-stats-layout *ngIf="statistics?.comics?.recent.length>0" [layout]="['card']" [title]="'ui.comics'|translate"
                                [subtitle]="'ui.comicsStatistics' | translate : statistics.comics"
                                [recent]="statistics.comics.recent"
                                [routeBase]="'/comic-library/series'">
      </app-library-stats-layout>

      <app-library-stats-layout *ngIf="statistics?.movies?.recent.length>0" [layout]="['card']" [title]="'ui.movies'|translate"
                                [subtitle]="'ui.moviesStatistics' | translate : statistics.movies"
                                [recent]="statistics.movies.recent"
                                [routeBase]="''">
      </app-library-stats-layout>

      <app-library-stats-layout *ngIf="statistics?.shows?.recent.length>0" [layout]="['card']" [title]="'ui.shows'|translate"
                                [subtitle]="'ui.showsStatistics' | translate : statistics.shows"
                                [recent]="statistics.shows.recent"
                                [routeBase]="'/show-library/shows'">
      </app-library-stats-layout>

    </div>

  </page-content>

</app-main-layout>
