<div *ngIf="layout=='card'"
     [class.is-more]="isMore"
     [class.is-mobile]="screenDetectionService.isMobile"
     class="item card">

  <mat-card>

    <img style="width:100%;" mat-mdc-card-image appImgFallback src="{{globalStateService.serverUrl}}/{{item.previewImageUrl}}">

    <mat-card-header>

      <mat-card-title>
        <span *ngIf="item.season">
          <app-editable [enabled]="editableIsEnabled('season')"
                        [type]="'season'"
                        [data]="item.season"
                        (editableUpdated)="onEditableUpdated('season',$event)"></app-editable>x
        </span>
        <span *ngIf="item.number">
          <span *ngIf="!item.season">#</span>
          <app-editable [enabled]="editableIsEnabled('number')"
                        [type]="'number'"
                        [data]="item.number"
                        (editableUpdated)="onEditableUpdated('number',$event)"></app-editable>
        </span>
        <span *ngIf="(item.season || item.number) && item.title || item.proposedSeriesTitle || (item.parent && item.parent.title)">: </span>
        <app-editable [enabled]="editableIsEnabled('title') && item.title"
                      [data]="item.title || item.proposedSeriesTitle || item.parent.title"
                      (editableUpdated)="onEditableUpdated('title',$event)"></app-editable>
      </mat-card-title>
      <mat-card-subtitle>
        <div *ngIf="item.authorName">
          <app-editable [enabled]="editableIsEnabled('authorName')"
                        [data]="item.authorName"
                        (editableUpdated)="onEditableUpdated('authorName',$event)"></app-editable>
        </div>
        <div *ngIf="item.year">
          <app-editable [enabled]="editableIsEnabled('year')"
                        [type]="'item.number'" [data]="item.year"
                        (editableUpdated)="onEditableUpdated('year',$event)"></app-editable>
        </div>
        <div *ngIf="item.progressFormatted">{{'ui.progress'|translate}}: <b>{{item.progressFormatted}}</b></div>
        <div *ngIf="item.relativePath"><i class="la la-folder-open la-fw"></i> {{item.relativePath}}</div>
        <app-stream-descriptor [data]="item.streamDescriptor" *ngIf="item.streamDescriptor"></app-stream-descriptor>
      </mat-card-subtitle>

    </mat-card-header>


    <mat-card-content>
      <div class="more" *ngIf="isMore">
        <div>
          <i *ngIf="item.directors" [innerHTML]="item.directors"></i>
        </div>
        <div>
          <i *ngIf="item.genres" [innerHTML]="item.genres"></i>
        </div>
        <div *ngIf="item.description" [innerHTML]="item.description"></div>
        <div *ngIf="item.plot" [innerHTML]="item.plot"></div>
      </div>
      <circle-progress [percent]="item.progressPercent*100.0"></circle-progress>
    </mat-card-content>

    <mat-card-actions>
      <button mat-button type="button" (click)="onMoreClicked()">
        <i class="la la-compress la-fw" *ngIf="isMore"></i>
        <i class="la la-expand la-fw" *ngIf="!isMore"></i>
        {{(isMore ? "ui.less" : "ui.more") | translate}}
      </button>
      <ng-content select="item-primary-actions"></ng-content>
      <ng-content select="item-secondary-actions"></ng-content>
    </mat-card-actions>
  </mat-card>

</div>


<div *ngIf="layout=='list'" [class.is-more]="isMore" [class.is-mobile]="screenDetectionService.isMobile"
     class="item list">

  <div>
    <img appImgFallback src="{{globalStateService.serverUrl}}/{{item.previewImageUrl}}">
    <circle-progress [radius]="10" [percent]="item.progressPercent*100.0"></circle-progress>
  </div>
  <div>
    <span *ngIf="item.season">
      <app-editable [enabled]="editableIsEnabled('season')" [type]="'season'" [data]="item.season"
                    (editableUpdated)="onEditableUpdated('season',$event)"></app-editable>x
    </span>
    <span *ngIf="item.number">
      <span *ngIf="!item.season">#</span>
      <app-editable [enabled]="editableIsEnabled('number')" [type]="'number'" [data]="item.number"
                    (editableUpdated)="onEditableUpdated('number',$event)"></app-editable>
    </span>
    <span *ngIf="item.season||item.number">: </span>
    <app-editable [enabled]="editableIsEnabled('title')" [data]="item.title"
                  (editableUpdated)="onEditableUpdated('title',$event)"></app-editable>
  </div>
  <div>
    <app-editable *ngIf="item.authorName" [enabled]="editableIsEnabled('authorName')" [data]="item.authorName"
                  (editableUpdated)="onEditableUpdated('authorName',$event)"></app-editable>
  </div>
  <div>
    <app-editable *ngIf="item.year" [enabled]="editableIsEnabled('year')" [type]="'number'" [data]="item.year"
                  (editableUpdated)="onEditableUpdated('year',$event)"></app-editable>
  </div>

  <div class="more" *ngIf="isMore">
    <div>
      <i *ngIf="item.directors" [innerHTML]="item.directors"></i>
    </div>
    <div>
      <i *ngIf="item.genres" [innerHTML]="item.genres"></i>
    </div>
    <div *ngIf="item.description" [innerHTML]="item.description"></div>
    <div *ngIf="item.plot" [innerHTML]="item.plot"></div>
  </div>

  <div>
    <button mat-button type="button" (click)="onMoreClicked()">
      <i class="la la-compress la-fw" *ngIf="isMore"></i>
      <i class="la la-expand la-fw" *ngIf="!isMore"></i>
      {{isMore ? "ui.less" : "ui.more" | translate}}
    </button>
    <ng-content select="item-primary-actions"></ng-content>
    <ng-content select="item-secondary-actions"></ng-content>
  </div>

</div>
