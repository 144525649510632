<app-main-layout [backgroundImage]="profileSettingsService.defaultBackgroundImage"
                 exitRoute="/home">

  <page-title>
    {{'ui.profileSettings'|translate}}

  </page-title>

  <library-tools>
  </library-tools>

  <page-content>

    <mat-tab-group>

      <mat-tab label="{{'ui.lookAndFeel'|translate}}">

        <div class="items">

          <mat-card class="editor-block">
            <mat-card-header>
              <mat-card-title>{{'ui.general'|translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field>
                <mat-label>{{'ui.name'|translate}}</mat-label>
                <input matInput required (change)="onUpdateProperty('DisplayName', $event.target.value)"
                       [value]="profileSettingsService.displayName">
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{'ui.skin'|translate}}</mat-label>
                <mat-select (selectionChange)="onUpdateProperty('Skin', $event.value)"
                            [value]="profileSettingsService.skin">
                  <mat-option value="blue-theme">Blue</mat-option>
                  <mat-option value="blue-tango-theme">Blue tango</mat-option>
                  <mat-option value="red-theme">Red</mat-option>
                  <mat-option value="coffee-theme">Coffee</mat-option>
                  <mat-option value="relax-theme">Relax</mat-option>
                  <mat-option value="black-theme">Black</mat-option>
                  <mat-option value="journey-theme">Journey</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{'ui.avatar'|translate}}</mat-label>
                <mat-select (selectionChange)="onUpdateProperty('Avatar', $event.value)"
                            [value]="profileSettingsService.avatar">
                  <mat-option *ngFor="let item of avatars | keyvalue " [(value)]="item.value">
                    <img class="in-option-image" src='assets/images/avatar/64x64/{{item.value}}'> {{item.key}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <img class="preview-image" src='assets/images/avatar/64x64/{{profileSettingsService.avatar}}' />
            </mat-card-content>
          </mat-card>

          <mat-card class="editor-block">
            <mat-card-header>
              <mat-card-title>{{'ui.backgrounds'|translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field>
                <mat-label>{{'ui.default'|translate}}</mat-label>
                <mat-select (selectionChange)="onUpdateProperty('DefaultBackgroundImage', $event.value)"
                            [value]="profileSettingsService.defaultBackgroundImage">
                  <mat-option *ngFor="let item of defaultBackgrounds | keyvalue " [(value)]="item.key">
                    <img class="in-option-image" src='assets/images/backgrounds/{{item.key.replace(".jpg", "_tn.jpg")}}'>
                    {{item.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <img class="preview-image"
                   src='assets/images/backgrounds/{{profileSettingsService.defaultBackgroundImage.replace(".jpg", "_tn.jpg")}}' />
              <mat-form-field>
                <mat-label>{{'ui.comics'|translate}}</mat-label>
                <mat-select (selectionChange)="onUpdateProperty('ComicLibraryBackgroundImage', $event.value)"
                            [value]="profileSettingsService.comicLibraryBackgroundImage">
                  <mat-option *ngFor="let item of comicLibraryBackgrounds | keyvalue " [(value)]="item.key">
                    <img class="in-option-image" src='assets/images/backgrounds/{{item.key.replace(".jpg", "_tn.jpg")}}'>
                    {{item.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <img class="preview-image"
                   src='assets/images/backgrounds/{{profileSettingsService.comicLibraryBackgroundImage.replace(".jpg", "_tn.jpg")}}' />
              <mat-form-field>
                <mat-label>{{'ui.music'|translate}}</mat-label>
                <mat-select (selectionChange)="onUpdateProperty('MusicLibraryBackgroundImage', $event.value)"
                            [value]="profileSettingsService.musicLibraryBackgroundImage">
                  <mat-option *ngFor="let item of musicLibraryBackgrounds | keyvalue " [(value)]="item.key">
                    <img class="in-option-image" src='assets/images/backgrounds/{{item.key.replace(".jpg", "_tn.jpg")}}'>
                    {{item.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <img class="preview-image"
                   src='assets/images/backgrounds/{{profileSettingsService.musicLibraryBackgroundImage.replace(".jpg", "_tn.jpg")}}' />
              <mat-form-field>
                <mat-label>{{'ui.shows'|translate}}</mat-label>
                <mat-select (selectionChange)="onUpdateProperty('ShowLibraryBackgroundImage', $event.value)"
                            [value]="profileSettingsService.showLibraryBackgroundImage">
                  <mat-option *ngFor="let item of showLibraryBackgrounds | keyvalue " [(value)]="item.key">
                    <img class="in-option-image" src='assets/images/backgrounds/{{item.key.replace(".jpg", "_tn.jpg")}}'>
                    {{item.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <img class="preview-image"
                   src='assets/images/backgrounds/{{profileSettingsService.showLibraryBackgroundImage.replace(".jpg", "_tn.jpg")}}' />
              <mat-form-field>
                <mat-label>{{'ui.movies'|translate}}</mat-label>
                <mat-select (selectionChange)="onUpdateProperty('MovieLibraryBackgroundImage', $event.value)"
                            [value]="profileSettingsService.movieLibraryBackgroundImage">
                  <mat-option *ngFor="let item of movieLibraryBackgrounds | keyvalue " [(value)]="item.key">
                    <img class="in-option-image" src='assets/images/backgrounds/{{item.key.replace(".jpg", "_tn.jpg")}}'>
                    {{item.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <img class="preview-image"
                   src='assets/images/backgrounds/{{profileSettingsService.movieLibraryBackgroundImage.replace(".jpg", "_tn.jpg")}}' />
              <mat-form-field>
                <mat-label>{{'ui.books'|translate}}</mat-label>
                <mat-select (selectionChange)="onUpdateProperty('BookLibraryBackgroundImage', $event.value)"
                            [value]="profileSettingsService.bookLibraryBackgroundImage">
                  <mat-option *ngFor="let item of bookLibraryBackgrounds | keyvalue " [(value)]="item.key">
                    <img class="in-option-image" src='assets/images/backgrounds/{{item.key.replace(".jpg", "_tn.jpg")}}'>
                    {{item.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <img class="preview-image"
                   src='assets/images/backgrounds/{{profileSettingsService.bookLibraryBackgroundImage.replace(".jpg", "_tn.jpg")}}' />
            </mat-card-content>
          </mat-card>

        </div>

      </mat-tab>

      <mat-tab label="{{'ui.access'|translate}}">

        <div class="items">

          <mat-card class="editor-block">
            <mat-card-header>
              <mat-card-title>{{'ui.linkedIdentities'|translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div *ngFor="let item of profileSettingsService.profile.linkedIdentities;">
                {{item.displayName|shorten:30}}
                <button *ngIf="profileSettingsService.profile.linkedIdentities.length>0" mat-icon-button
                        (click)="onRemoveLinkedIdentityButtonClick(item.id)">
                  <i class="la la-trash la-fw"></i>
                </button>
              </div>
              <mat-card>
                <mat-card-content>

                  <mat-form-field>
                    <mat-label>{{'ui.displayName'|translate}}</mat-label>
                    <input required type="text" [(ngModel)]="newIdentity.displayName" matInput>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'ui.userName'|translate}}</mat-label>
                    <input required type="text" [(ngModel)]="newIdentity.key" matInput>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'ui.password'|translate}}</mat-label>
                    <input required type="text" [(ngModel)]="newIdentity.password" matInput>
                  </mat-form-field>
                  <button mat-button (click)="onAddLinkedIdentityButtonClick()">
                    <i class="la la-plus-circle la-fw"></i>
                    {{'actions.add'|translate}}
                  </button>
                </mat-card-content>

              </mat-card>
            </mat-card-content>
          </mat-card>

          <mat-card class="editor-block">
            <mat-card-header>
              <mat-card-title>{{'ui.apiKeys'|translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div *ngFor="let item of profileSettingsService.profile.apiKeys;">
                {{ item.displayName |shorten:30}}
                <button mat-icon-button (click)="onRemoveAPIKeyButtonClick(item.id)">
                  <i class="la la-trash la-fw"></i>
                </button>
              </div>
              <div>
                <button mat-button (click)="onAddAPIKeyButtonClick()"><i class="la la-plus-circle la-fw"></i> {{'actions.add'|translate}}</button>
              </div>
            </mat-card-content>
          </mat-card>

        </div>

      </mat-tab>

      <mat-tab label="{{'ui.publishing'|translate}}" class="items">

        <div class="items">

          <mat-card class="editor-block">

            <mat-card-header>
              <mat-card-title>{{'ui.devices'|translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div *ngFor="let item of profileSettingsService.profile.devices;">
                <span>
                  <b>{{ item.name }}</b>
                  ({{ item.pushMethod }})
                </span>
                <button mat-icon-button (click)="onRemoveDeviceButtonClick(item.id)">
                  <i class="la la-trash la-fw"></i>
                </button>
                <button mat-icon-button (click)="onEditDeviceButtonClick(item.id)">
                  <i class="la la-edit la-fw"></i>
                </button>
              </div>

              <mat-card>
                <mat-card-content>

                  <mat-form-field>
                    <mat-label>{{'ui.name'|translate}}</mat-label>
                    <input required type="text" [(ngModel)]="newDevice.name" matInput>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'ui.pushMethod'|translate}}</mat-label>
                    <mat-select [(ngModel)]="newDevice.pushMethod">
                      <mat-option *ngFor="let item of devicePushMethods | keyvalue " [value]="item.key">
                        {{item.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>{{'ui.diskQuota'|translate}} (GB)</mat-label>
                    <input type="number" [(ngModel)]="newDevice.diskQuotaInGB" matInput>
                  </mat-form-field>

                  <mat-slide-toggle color="primary" [(ngModel)]="newDevice.flattenDirectoryStructure" matInput>
                    {{'ui.flattenDirectoryStructure'|translate}}
                  </mat-slide-toggle>

                  <div *ngIf="newDevice.pushMethod">
                    <mat-label>{{'ui.publishSettings'|translate}}</mat-label>

                    <div *ngIf="newDevice.pushMethod=='FTP'">

                      <mat-form-field>
                        <mat-label>{{'ui.url'|translate}}</mat-label>
                        <input required type="text" [(ngModel)]="newDevice.pushSettings.ftpUrl" matInput>
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>{{'ui.userName'|translate}}</mat-label>
                        <input required type="text" [(ngModel)]="newDevice.pushSettings.ftpUser" matInput>
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>{{'ui.password'|translate}}</mat-label>
                        <input required type="text" [(ngModel)]="newDevice.pushSettings.ftpPassword" matInput>
                      </mat-form-field>

                    </div>

                    <div *ngIf="newDevice.pushMethod=='FILECOPY'">

                      <mat-form-field>
                        <mat-label>{{'ui.folderPath'|translate}}</mat-label>
                        <input required type="text" [(ngModel)]="newDevice.pushSettings.folderPath" matInput>
                      </mat-form-field>

                      <mat-slide-toggle color="primary" [(ngModel)]="newDevice.convertAudioToMp3" matInput>
                        {{'ui.convertAudioToMp3'|translate}} (192Kbps)
                      </mat-slide-toggle>

                      <mat-slide-toggle color="primary" [(ngModel)]="newDevice.convertVideoToMp4" matInput>
                        {{'ui.convertVideoToMp4'|translate}}
                      </mat-slide-toggle>

                    </div>

                  </div>
                </mat-card-content>
                <mat-card-actions>
                  <button *ngIf="!newDevice.id" mat-button (click)="onAddDeviceButtonClick();">
                    <i class="la la-plus-circle la-fw"></i> {{'actions.add'|translate}}
                  </button>
                  <button *ngIf="newDevice.id" mat-button (click)="onUpdateDeviceButtonClick();">
                    <i class="la la-save la-fw"></i> {{'actions.save'|translate}}
                  </button>
                  <button *ngIf="newDevice.id" mat-button (click)="onCancelUpdateDeviceButtonClick();">
                    <i class="la la-undo la-fw"></i> {{'actions.cancel'|translate}}
                  </button>
                </mat-card-actions>

              </mat-card>

            </mat-card-content>
          </mat-card>

          <mat-card class="editor-block">
            <mat-card-header>
              <mat-card-title>{{'ui.favouriteMusic'|translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{'ui.publishMyFavouriteMusic'|translate}}</p>

              <mat-list>
                <mat-list-item *ngFor="let item of profileSettingsService.profile.devices;">
                  <mat-slide-toggle [(ngModel)]="item.favouriteMusicDestination"
                                    (change)="onFavouriteMusicDestinationChange(item)">
                    <b>{{ item.name }}</b>
                    ({{ item.pushMethod }})
                  </mat-slide-toggle>
                </mat-list-item>
              </mat-list>

            </mat-card-content>
          </mat-card>

        </div>

      </mat-tab>

    </mat-tab-group>

  </page-content>


</app-main-layout>
