/* src/app/layouts/item-layout/item-layout.component.scss */
.item.card {
  display: inline-block;
  vertical-align: top;
}
.item.card .details {
  padding: 10px;
}
.item.card circle-progress {
  position: absolute;
  top: 0;
  left: 0;
}
.item.list {
  display: grid;
  grid-template-columns: 120px 2fr 1fr 1fr 1fr 1fr;
  width: 100%;
  position: relative;
}
.item.list circle-progress {
  position: absolute;
  top: 0;
  left: 0;
}
.item.list img {
  width: 100%;
}
.item.list > * {
  padding: 10px;
  box-sizing: border-box;
}
/*# sourceMappingURL=item-layout.component.css.map */
