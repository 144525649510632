/* src/app/layouts/library-stats-layout/library-stats-layout.component.scss */
.item circle-progress {
  position: absolute;
  top: 0;
  left: 0;
}
.item-preview {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: block;
  height: 400px;
  position: relative;
}
.item-preview span {
  display: block;
  position: absolute;
  bottom: 0px;
  padding: 10px;
  text-align: center;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  color: #FFF;
}
.actions {
  display: flex;
  justify-content: space-between;
}
.actions .action {
  opacity: 0.8;
  font-size: 4vh;
}
/*# sourceMappingURL=library-stats-layout.component.css.map */
