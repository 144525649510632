<app-main-layout #mainLayout [backgroundImage]="backgroundImage" [exitRoute]="exitRoute">

  <page-title>
    <ng-content select="page-title"></ng-content>
  </page-title>

  <page-content>
    <mat-drawer-container>

      <mat-drawer class="page-filters" #filters mode="over" position="end">

        <div *ngIf="availableBasePaths && availableBasePaths.length>1">

          <h2>{{'ui.basePaths'|translate}}</h2>
          <mat-form-field>
            <mat-label>{{'ui.basePath'|translate}}</mat-label>
            <mat-select [value]="basePath" (selectionChange)="onBasePathChange($event)">
              <mat-option [value]="undefined">{{'ui.all'|translate}}</mat-option>
              <mat-option *ngFor="let availableBasePath of availableBasePaths" [value]="availableBasePath">
                {{availableBasePath}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>


        <div *ngIf="availableViews && availableViews.length>0">

          <h2>{{'ui.views'|translate}}</h2>

          <mat-list>
            <mat-list-item *ngFor="let availableView of availableViews">
              <mat-slide-toggle [name]="availableView"
                                [color]="'primary'" [checked]="views.indexOf(availableView)>-1"
                                (change)="onViewSliderChange($event)">
                {{'views.' + availableView | translate}}
              </mat-slide-toggle>
            </mat-list-item>
          </mat-list>

        </div>

        <div *ngIf="availableInitials && availableInitials.length>0">

          <h2>{{'ui.initials'|translate}}</h2>

          <div>
            <button mat-stroked-button *ngFor="let availableInitial of availableInitials" type="button" (click)="onSearchText(availableInitial)">
              {{availableInitial}}
            </button>
            <button mat-stroked-button color="primary" type="button" (click)="onSearchText('')">
              <i class="las la-eraser"></i>
            </button>
          </div>

        </div>

        <ng-content select="page-additional-filters"></ng-content>

      </mat-drawer>

      <mat-drawer-content scrollEvent (onscroll)="onDrawerScroll($event)" [bottomOffset]="400" [topOffset]="200" #drawerContent>
        <ng-content select="page-content"></ng-content>
      </mat-drawer-content>

    </mat-drawer-container>
  </page-content>

  <library-tools>

    <form #searchForm="ngForm" (ngSubmit)="onSearchText(searchTextInput.value)" [style.fontSize.px]="14">

      <mat-form-field  class="no-subscript" [style.width.px]="200" [style.marginRight.px]="10">
        <input matInput placeholder="{{'ui.search'|translate}}" #searchTextInput name="searchText" [value]="searchText?searchText:''">
        <button mat-icon-button matSuffix type="submit">
          <i class="la la-search la-fw"></i>
        </button>
        <button mat-icon-button matSuffix (click)="onSearchText('')">
          <i class="la la-times la-fw"></i>
        </button>
      </mat-form-field>

      <button mat-button type="button" (click)="filters.toggle()">
        <i class="la la-filter la-fw"></i> <span *ngIf="!screenDetectionService.isMobile">{{'actions.toggleFilters'|translate}}</span>
      </button>

    </form>

  </library-tools>

</app-main-layout>
