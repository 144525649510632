<app-library-explorer2-layout (scrolledToBottom)="onScrolledToBottom()"
                             [backgroundImage]="profileSettingsService.comicLibraryBackgroundImage"
                             [(searchText)]="stateService.containerItemsSearchText"
                             [availableBasePaths]="stateService.basePaths"
                             [(basePath)]="stateService.currentBasePath"
                             [availableViews]="stateService.containerItemsViews"
                             [(views)]="stateService.containerItemsSelectedViews"
                             [availableInitials]="stateService.containerItemsInitials"
                             [(initial)]="stateService.containerItemsSearchText"
                              exitRoute="/home">

  <page-title>{{'ui.series'|translate}}</page-title>

  <page-content>

    <div class="" *ngIf="stateService.containerItems.length==0">
      <h1>{{'ui.nothingToDisplay'|translate}}</h1>
    </div>

    <div class="items">

      <app-item-layout *ngFor="let item of stateService.containerItems"
                       [layout]="stateService.currentDisplayMode"
                       [item]="item"
                       [enabledEditables]="['title', 'year']"
                       (editableUpdated)="onEditableUpdated(item.id, $event)">

        <item-primary-actions>

          <button mat-button color="accent" [routerLink]="['/comic-library/series', item.id]">
            <i class="la la-play la-fw"></i> {{'actions.open'|translate}}
          </button>
          <button *ngIf="!item.blackHole && !item.ignoreScraping" mat-button (click)="onSearchComicVineButtonClick(item.id)">
            <i class="la la-search la-fw"></i> {{'actions.searchComicVine'|translate}}
          </button>
          <a *ngIf="!item.blackHole && item.comicVinePageUrl" mat-button [href]="item.comicVinePageUrl" target="_blank">
            <i class="la la-external-link-alt la-fw"></i> {{'actions.openOnComicVine'|translate}}
          </a>

          <mat-form-field>
            <mat-label>{{'actions.publishTo'|translate}}</mat-label>
            <mat-select multiple [compareWith]="compareToDevices" [ngModel]="item.toDevices">
              <mat-option (onSelectionChange)="onToDeviceSelectionChange($event,item.id)" *ngFor="let device of profileSettingsService.profile.devices;" [value]="device.id">{{device.name}} ({{device.pushMethod}})</mat-option>
            </mat-select>
          </mat-form-field>

        </item-primary-actions>

        <item-secondary-actions>
          <button *ngIf="!item.blackHole" mat-button type="button" (click)="onMarkAsButtonClick(item.id, item.progressPercent < 0.99)">
            <i class="la la-check la-fw"></i> {{'actions.markAs'|translate}}
            {{(item.progressPercent >=0.99 ? 'ui.notRead' : 'ui.read')|translate}}
          </button>
          <button mat-button type="button" (click)="onForceInspectButtonClick(item.id)">
            <i class="la la-sync la-fw"></i> {{'actions.inspect'|translate}}
          </button>
          <button *ngIf="!item.blackHole" mat-button type="button" (click)="onChangeMonitorButtonClick(item.id, !item.monitor)">
            <i class="la la-calendar-check la-fw"></i>
            {{'actions.monitorStatus'|translate}}
            {{(item.monitor ? 'ui.on' : 'ui.off')|translate}}
          </button>
          <button *ngIf="!item.blackHole" mat-button type="button" (click)="onChangeIgnoreScrapingButtonClick(item.id, !item.ignoreScraping)">
            <i class="la la-search la-fw"></i>
            {{'actions.ignoreScrapingStatus'|translate}}
            {{(item.ignoreScraping ? 'ui.on' : 'ui.off')|translate}}
          </button>
          <button *ngIf="!item.blackHole" mat-button color="warn" type="button" (click)="onRemoveButtonClick(item.id)">
            <i class="la la-trash la-fw"></i> {{'actions.remove'|translate}}
          </button>
        </item-secondary-actions>

      </app-item-layout>

    </div>

  </page-content>

</app-library-explorer2-layout>
