<div class="item card">

  <mat-card *ngIf="recent">

    <a  mat-mdc-card-image [routerLink]="routeBase ? [routeBase, recent[currentItemIndex].id] : ''">
      <div class="item-preview" [style.background-image]="'url(' + globalStateService.serverUrl + '/' + recent[currentItemIndex].previewImageUrl + ')'">
        <span class="caption">{{recent[currentItemIndex].title}}</span>
      </div>
    </a>

    <mat-card-header>
      <mat-card-title *ngIf="title">{{title}}</mat-card-title>
      <mat-card-subtitle *ngIf="subtitle">{{subtitle}}</mat-card-subtitle>
      <circle-progress [percent]="recent[currentItemIndex].progressPercent*100.0"></circle-progress>
    </mat-card-header>

    <mat-card-actions class="actions">

      <button [disabled]="currentItemIndex==0" type="button" mat-icon-button class="action"
              (click)="prevItem()">
        <i class="la la-chevron-left"></i>
      </button>

      <button [disabled]="currentItemIndex==recent.length-1" type="button" mat-icon-button class="action"
              (click)="nextItem()">
        <i class="la la-chevron-right"></i>
      </button>

    </mat-card-actions>

  </mat-card>

</div>
