<app-main-layout [backgroundImage]="profileSettingsService.defaultBackgroundImage"
                 exitRoute="/home">

  <page-title>
    {{'ui.status'|translate}}
  </page-title>

  <page-content>

    <div class="items">

      <mat-card appearance="outlined" *ngFor="let hw of realTimeDataService.hardwareStatus " class="editor-block">
        <mat-card-header>
          <mat-card-title><i class="las" [ngClass]="iconForHardwareType(hw.type)"></i> {{hw.name}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div *ngFor="let load of  hw.loads |keyvalue">
            <i class="las la-tachometer-alt"></i> {{load.key}} : {{load.value | number:'1.0-0'}}%
          </div>

          <div *ngFor="let temperature of  hw.temperatures |keyvalue">
            <i class="las la-temperature-high"></i> {{temperature.key}} : {{temperature.value}}°
          </div>
        </mat-card-content>
      </mat-card>

    </div>

  </page-content>


</app-main-layout>
