<mat-form-field *ngFor="let item of items; let i = index">
  <mat-label>{{itemtitle}} {{i+1}}</mat-label>
  <input type="text"
         [(ngModel)]="items[i]"
         matInput>
  <button mat-icon-button matSuffix type="button" (click)="items.splice(i);"><i class="la la-minus-circle la-fw"></i></button>
</mat-form-field>
<mat-form-field>
  <mat-label>{{'ui.add'|translate}}</mat-label>
  <input type="text"
         #newItem
         matInput
         required
         [attr.minlength]="minlength"
         [pattern]="pattern">
  <button mat-button
          matSuffix
          type="button"
          [disabled]="!newItem.validity.valid || !newItem.value"
          (click)="items.push(newItem.value);newItem.value='';">
    <i class="la la-plus-circle la-fw"></i> {{'ui.add'|translate}}
  </button>
</mat-form-field>
