import localeIT from '@angular/common/locales/it';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxFilesizeModule } from 'ngx-filesize';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';

import { LoadingDetectionService } from './services/loading-detection.service';
import { CurrentProfileSettingsService } from './services/current-profile-settings.service';
import { GlobalStateService } from './services/global-state.service';
import { ScreenDetectionService } from './services/screen-detection.service';
import { AuthGuard } from './guards/auth.guard.service';
import { BookViewerComponent } from './pages/book-viewer/book-viewer.component';
import { UnsafeHtmlPipe } from './pipes/unsafe-html.pipe';
import { MusicPlayerStateService } from './services/music-player-state.service';
import { ComicViewerComponent } from './pages/comic-viewer/comic-viewer.component';
import { MusicLibraryStateService } from './services/library-state/music-library-state.service';
import { ApiBridgeClient, ComicLibraryClient, MusicLibraryClient, BookLibraryClient, MovieLibraryClient, ShowLibraryClient, ProposedDownloadsClient, ProfilesClient, CommonClient, ConfigClient, CurrentProfileClient, FileManagerClient, API_BASE_URL } from './api.client';
import { BookLibraryStateService } from './services/library-state/book-library-state.service';
import { ComicLibraryStateService } from './services/library-state/comic-library-state.service';
import { MovieLibraryStateService } from './services/library-state/movie-library-state.service';
import { ShowLibraryStateService } from './services/library-state/show-library-state.service';
import { ProposedDownloadsStateService } from './services/proposed-downloads-state.service';
import { FileManagerStateService } from './services/file-manager-state.service';

import { BookLibraryComponent } from './pages/book-library/book-library.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { LibraryExplorerLayoutComponent } from './layouts/library-explorer-layout/library-explorer-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { ItemLayoutComponent } from './layouts/item-layout/item-layout.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { MusicLibraryComponent } from './pages/music-library/music-library.component';
import { SeriesComponent } from './pages/comic-library/series/series.component';
import { IssuesComponent } from './pages/comic-library/issues/issues.component';
import { MovieLibraryComponent } from './pages/movie-library/movie-library.component';
import { SearchOnComicVineDialogComponent } from './bits/search-on-comicvine-dialog/search-on-comicvine-dialog.component';
import { SearchOnTMDBDialogComponent } from './bits/search-on-tmdb-dialog/search-on-tmdb-dialog.component';
import { ShowsComponent } from './pages/show-library/shows/shows.component';
import { EpisodesComponent } from './pages/show-library/episodes/episodes.component';
import { ProposedDownloadsComponent } from './pages/proposed-downloads/proposed-downloads.component';
import { HangfireComponent } from './pages/hangfire/hangfire.component';
import { StringListEditorComponent } from './bits/string-list-editor/string-list-editor.component';
import { LibraryStatsLayoutComponent } from './layouts/library-stats-layout/library-stats-layout.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { EmbeddedAppsComponent } from './pages/embedded-apps/embedded-apps.component';
import { FilemanagerComponent } from './pages/filemanager/filemanager.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';
import { ReceivedMessagesComponent } from './pages/received-messages/received-messages.component';
import { MusicPlayerComponent } from './bits/music-player/music-player.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpHeadersInterceptor } from './interceptors/http-headers.interceptor';
import { RealTimeDataService } from './services/real-time-data-service';
import { ConfigurationService } from './services/configuration-service';
import { ConfirmDialogComponent } from './bits/confirm-dialog/confirm-dialog.component';
import { EditableComponent } from './bits/editable/editable.component';
import { ImgFallbackDirective } from './directives/img-fallback.directive';
import { FilterByInitialOrPartPipe } from './pipes/filter-by-initial-or-part.pipe';
import { GetInitialsPipe } from './pipes/get-initials.pipe';
import { GroupByInitialPipe } from './pipes/group-by-initial.pipe';
import { ParseJsonPipe } from './pipes/parse-json-pipe';
import { UnsafeUrlPipe } from './pipes/unsafe-url-pipe';
import { LoginComponent } from './pages/login/login.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { registerLocaleData } from '@angular/common';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { ScrollEventDirective } from './directives/scroll-event.directive';
import { LibraryExplorer2LayoutComponent } from './layouts/library-explorer2-layout/library-explorer2-layout.component';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { StatusComponent } from './pages/status/status.component';
import { StreamDescriptorComponent } from './bits/stream-descriptor/stream-descriptor.component';

export function circleTitleFormat(percent: number): string {
  return Math.round(percent) + '%';
}

export function apiBaseUrlFactory(globalStateService: GlobalStateService): string {
  return globalStateService.serverUrl;
}

registerLocaleData(localeIT);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        BookViewerComponent,
        UnsafeHtmlPipe,
        ComicViewerComponent,
        BookLibraryComponent,
        MainLayoutComponent,
        LibraryExplorerLayoutComponent,
        HomeComponent,
        ItemLayoutComponent,
        ProfileSettingsComponent,
        MusicLibraryComponent,
        SeriesComponent,
        IssuesComponent,
        MovieLibraryComponent,
        SearchOnComicVineDialogComponent,
        SearchOnTMDBDialogComponent,
        ShowsComponent,
        EpisodesComponent,
        ProposedDownloadsComponent,
        HangfireComponent,
        LibraryStatsLayoutComponent,
        ConfigurationComponent,
        FilemanagerComponent,
        ReceivedMessagesComponent,
        EmbeddedAppsComponent,
        MusicPlayerComponent,
        ProfilesComponent,
        StringListEditorComponent,
        ConfirmDialogComponent,
        GroupByInitialPipe,
        FilterByInitialOrPartPipe,
        GetInitialsPipe,
        ParseJsonPipe,
        ImgFallbackDirective,
        UnsafeUrlPipe,
        EditableComponent,
        ScrollEventDirective,
        LibraryExplorer2LayoutComponent,
        CapitalizeFirstPipe,
        StatusComponent,
        StreamDescriptorComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatRippleModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatCardModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatListModule,
        MatChipsModule,
        MatBadgeModule,
        MatExpansionModule,
        MatTabsModule,
        MatTableModule,
        MatTooltipModule,
        MatSnackBarModule,
        FormsModule,
        ReactiveFormsModule,
        PinchZoomModule,
        ClipboardModule,
        NgxFilesizeModule,
        LazyLoadImageModule,
        NgPipesModule,
        NgCircleProgressModule.forRoot({
            "backgroundPadding": 7,
            "backgroundColor": "#ffffff",
            "backgroundOpacity": 0.95,
            "radius": 22,
            "space": -2,
            "maxPercent": 100,
            "outerStrokeWidth": 2,
            "outerStrokeColor": "#000000",
            "outerStrokeLinecap": "square",
            "innerStrokeColor": "#e7e8ea",
            "innerStrokeWidth": 2,
            "titleFontSize": "16",
            "subtitleFontSize": "14",
            "animation": false,
            "animateTitle": false,
            "animationDuration": 1000,
            "showTitle": true,
            "showSubtitle": false,
            "showUnits": false,
            "showBackground": true,
            "clockwise": false,
            "startFromZero": false,
            "showZeroOuterStroke": false,
            titleFormat: circleTitleFormat
        }),
        MonacoEditorModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })], providers: [
        LoadingDetectionService,
        CurrentProfileSettingsService,
        ScreenDetectionService,
        AuthGuard,
        BookLibraryClient,
        MusicLibraryClient,
        ComicLibraryClient,
        ApiBridgeClient,
        MovieLibraryClient,
        ShowLibraryClient,
        ProposedDownloadsClient,
        ProfilesClient,
        CommonClient,
        ConfigClient,
        CurrentProfileClient,
        FileManagerClient,
        GlobalStateService,
        MusicPlayerStateService,
        MusicLibraryStateService,
        ComicLibraryStateService,
        BookLibraryStateService,
        MovieLibraryStateService,
        ShowLibraryStateService,
        ProposedDownloadsStateService,
        FileManagerStateService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
        {
            provide: API_BASE_URL,
            useFactory: apiBaseUrlFactory,
            deps: [GlobalStateService]
        },
        { provide: LOCALE_ID, useValue: navigator.language },
        RealTimeDataService,
        ConfigurationService,
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
