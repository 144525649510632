/* src/app/layouts/library-explorer2-layout/library-explorer2-layout.component.scss */
.filters-menu {
  padding: 10px;
}
@media (max-width: 1279.9px) {
  .mat-mdc-button {
    min-width: 40px;
    padding: 4px;
  }
}
library-tools form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.mat-drawer-container {
  height: 100%;
  background-color: transparent;
}
mat-drawer {
  width: auto;
  max-width: 300px;
  padding: 20px;
}
mat-drawer-content {
  overflow-x: hidden;
}
page-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
/*# sourceMappingURL=library-explorer2-layout.component.css.map */
