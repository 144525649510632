<app-main-layout [backgroundImage]="profileSettingsService.defaultBackgroundImage"
                 exitRoute="/home">

  <page-title>
    {{'ui.profiles'|translate}}
  </page-title>

  <library-tools>
  </library-tools>

  <page-content>

    <div class="items">

      <div class="item card" *ngFor="let profile of profiles;">
        <div class="">
          <mat-card>
            <img mat-card-image src='assets/images/avatar/128x128/{{profile.avatar}}' />
            <mat-card-header>
              <mat-card-title>{{profile.displayName|shorten:30}}</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
              <button *ngIf="profile.id != this.profileSettingsService.profile.id" mat-button color="warn" type="button" (click)="onRemoveButtonClick(profile)">
                <i class="la la-trash la-fw"></i> {{'actions.remove'|translate}}
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>

      <div class="item card">
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{'ui.newProfile'|translate}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-form-field>
              <mat-label>{{'ui.name'|translate}}</mat-label>
              <input matInput required [(ngModel)]="newProfile.displayName">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'ui.userName'|translate}}</mat-label>
              <input required type="text" [(ngModel)]="newLinkedIdentity.key" matInput>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'ui.password'|translate}}</mat-label>
              <input required type="text" [(ngModel)]="newLinkedIdentity.password" matInput>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'ui.skin'|translate}}</mat-label>
              <mat-select [(ngModel)]="newProfile.skin">
                <mat-option value="blue-light-theme">Blue light</mat-option>
                <mat-option value="blue-dark-theme">Blue dark</mat-option>
                <mat-option value="red-light-theme">Red light</mat-option>
                <mat-option value="red-dark-theme">Red dark</mat-option>
                <mat-option value="black-light-theme">Black light</mat-option>
                <mat-option value="black-dark-theme">Black dark</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'ui.avatar'|translate}}</mat-label>
              <mat-select [(ngModel)]="newProfile.avatar">
                <mat-option *ngFor="let item of avatars | keyvalue " [(value)]="item.value">
                  <img class="in-option-image" src='assets/images/avatar/64x64/{{item.value}}'> {{item.key}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button color="primary" type="button" (click)="onAddButtonClick()">
              <i class="la la-plus-circle la-fw"></i> {{'actions.add'|translate}}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>


    </div>

  </page-content>


</app-main-layout>
