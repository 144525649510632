/* src/app/bits/music-player/music-player.component.scss */
:host {
  color: #fff;
  background-color: #333;
}
.audio-tag-wrapper .title {
  font-size: 1.5em;
}
.audio-tag-wrapper audio {
  width: 100%;
  display: block;
}
/*# sourceMappingURL=music-player.component.css.map */
