<app-library-explorer2-layout (scrolledToBottom)="onScrolledToBottom()"
                              [backgroundImage]="profileSettingsService.showLibraryBackgroundImage"
                              [(searchText)]="stateService.contentItemsSearchText"
                              [availableBasePaths]="stateService.basePaths"
                              [(basePath)]="stateService.currentBasePath"
                              [availableViews]="stateService.contentItemsViews"
                              [(views)]="stateService.contentItemsSelectedViews"
                              [availableInitials]="stateService.contentItemsInitials"
                              [(initial)]="stateService.contentItemsSearchText"
                              exitRoute="/show-library">

  <page-title>{{show?.title}}</page-title>

  <page-content>

    <div class="" *ngIf="stateService.contentItems.length==0">
      <h1>Nothing to display!</h1>
    </div>

    <div class="items">

      <app-item-layout *ngFor="let item of stateService.contentItems"
                       [layout]="['card']"
                       [item]="item">

        <item-primary-actions>
        </item-primary-actions>

        <item-secondary-actions>
          <button mat-button type="button"
                  (click)="onMarkAsButtonClick(item.id, item.progressPercent < 0.99)">
            <i class="la la-check la-fw"></i> {{'actions.markAs'|translate}}
            {{(item.progressPercent >=0.99 ? 'ui.notRead' : 'ui.read')|translate}}
          </button>
        </item-secondary-actions>

      </app-item-layout>

      </div>

  </page-content>

</app-library-explorer2-layout>
