/* src/app/app.component.scss */
.progress {
  position: fixed;
  bottom: 0px;
  z-index: 101;
  width: 100%;
}
.layout {
  display: flex;
  flex-direction: column;
}
/*# sourceMappingURL=app.component.css.map */
