<app-main-layout #mainLayout [backgroundImage]="backgroundImage">

  <page-title>
    <ng-content select="page-title"></ng-content>
  </page-title>

  <page-content>
    <mat-drawer-container>

      <mat-drawer class="page-filters" #filters mode="over" [opened]="showFilters" position="end">
        <ng-content select="page-filters"></ng-content>
      </mat-drawer>

      <mat-drawer-content scrollEvent (onscroll)="onDrawerScroll($event)" [bottomOffset]="200" [topOffset]="200" #drawerContent>
        <ng-content select="page-content"></ng-content>
      </mat-drawer-content>

    </mat-drawer-container>
  </page-content>

  <library-tools>

    <form #searchForm="ngForm" (ngSubmit)="onSearchFormSubmit($event)">

      <mat-form-field [style.width.px]=110>
        <input matInput placeholder="search" name="searchText" [value]="searchText?searchText:''">
      </mat-form-field>

      <button mat-icon-button type="submit">
        <i class="la la-search la-fw"></i>
      </button>

      <button mat-icon-button (click)="onClearSearchTextButtonClick()">
        <i class="la la-times la-fw"></i>
      </button>

      <!-- <button type="button" mat-button (click)="onChangeDisplayModeClick()">
        <i *ngIf="currentDisplayMode == 'card'" class="la la-th-list la-fw"></i>
        <i *ngIf="currentDisplayMode == 'list'" class="la la-th-large la-fw"></i> 
        <span *ngIf="!screenDetectionService.isMobile">Switch display mode</span>
      </button> -->

      <button mat-button type="button" (click)="onToggleFiltersClick()">
        <i class="la la-filter la-fw"></i> <span *ngIf="!screenDetectionService.isMobile">Filters</span>
      </button>

    </form>

  </library-tools>

</app-main-layout>
