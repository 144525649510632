/* src/app/bits/search-on-comicvine-dialog/search-on-comicvine-dialog.component.scss */
.mat-mdc-dialog-content {
  max-height: 40vh;
  overflow: auto;
}
.mat-mdc-dialog-content .search-results .search-result {
  display: flex;
}
@media (max-width: 959.9px) {
  .mat-mdc-dialog-content .search-results .search-result {
    flex-direction: column;
  }
}
@media (min-width: 960px) {
  .mat-mdc-dialog-content .search-results .search-result {
    flex-direction: row;
  }
}
.mat-mdc-dialog-content .search-results .search-result img,
.mat-mdc-dialog-content .search-results .search-result .metadata {
  padding: 10px;
}
@media (max-width: 959.9px) {
  .mat-mdc-dialog-content .search-results .search-result img,
  .mat-mdc-dialog-content .search-results .search-result .metadata {
    width: 100%;
  }
}
@media (min-width: 960px) {
  .mat-mdc-dialog-content .search-results .search-result img,
  .mat-mdc-dialog-content .search-results .search-result .metadata {
    width: 50%;
  }
}
/*# sourceMappingURL=search-on-comicvine-dialog.component.css.map */
