<app-main-layout [backgroundImage]="profileSettingsService.defaultBackgroundImage"
                 exitRoute="/home">

  <page-title>{{'ui.embeddedApps'|translate}}</page-title>

  <page-content>

    <mat-tab-group *ngIf="embeddedApps">

      <mat-tab *ngFor="let embeddedApp of embeddedApps | keyvalue" [label]="embeddedApp.value">

        <ng-template mat-tab-label>
          <a mat-icon-button [href]="embeddedApp.key | unsafeUrl" target="_blank">
            <i class="las la-external-link-alt"></i>
          </a>
          {{embeddedApp.value}}
        </ng-template>

        <iframe style="width:100%; height: 100%; display:block;" [src]="embeddedApp.key | unsafeUrl" frameborder="0" scrolling="yes"></iframe>

      </mat-tab>

    </mat-tab-group>

  </page-content>

</app-main-layout>
