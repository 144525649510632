<mat-drawer-container *ngIf="profileSettingsService.loaded"
                      [class.is-mobile]="screenDetectionService.isMobile"
                      class="mat-body-1">

  <mat-drawer #drawer [position]="screenDetectionService.isMobile ? 'end' : 'start'"
              [mode]="screenDetectionService.isMobile ? 'over' : 'side'"
              [opened]="screenDetectionService.isMobile ?  openDrawer : ''">

    <div class="mat-headline-5">
      Alessandria <small>v. {{globalStateService.version}}</small>
    </div>

    <mat-nav-list>

      <div class="user">
        <a mat-list-item routerLinkActive="active-link"
           [routerLink]="['/profile-settings']">
          <img *ngIf="profileSettingsService.avatar" class="avatar" src='assets/images/avatar/64x64/{{profileSettingsService.avatar}}' />
          {{profileSettingsService.displayName}}
        </a>
        <button mat-icon-button color="warn" (click)="onLogoutClick()"><i class="la la-times-circle la-fw"></i></button>
      </div>

      <mat-divider></mat-divider>

      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/home']">
        <i class="la la-home la-fw"></i> Home
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/music-library']">
        <i class="la la-headphones la-fw"></i> {{'ui.music'|translate}}
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/comic-library']">
        <i class="la la-comment-alt la-fw"></i> {{'ui.comics'|translate}}
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/book-library']">
        <i class="la la-book la-fw"></i> {{'ui.books'|translate}}
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/movie-library']">
        <i class="la la-film la-fw"></i> {{'ui.movies'|translate}}
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/show-library']">
        <i class="la la-tv la-fw"></i> {{'ui.shows'|translate}}
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/filemanager']">
        <i class="la la-archive la-fw"></i> {{'ui.fileManager'|translate}}
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/proposed-downloads']">
        <i class="la la-cloud-download-alt la-fw"></i> {{'ui.proposedDownloads'|translate}}
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/configuration']">
        <i class="la la-sliders-h la-fw"></i> {{'ui.configuration'|translate}}
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/profiles']">
        <i class="la la-users la-fw"></i> {{'ui.profiles'|translate}}
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/embedded-apps']">
        <i class="la la-window-maximize la-fw"></i> {{'ui.embeddedApps'|translate}}
      </a>

      <mat-divider></mat-divider>

      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/received-messages']">
        <i class="la la-bell la-fw"></i>
        {{'ui.messagesCount'|translate}}: <b>{{realTimeDataService.messagesCount}}</b>
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/scheduled-tasks']">
        <i [class.blink]="realTimeDataService.runningOperationsCount>0" class="la la-tasks la-fw"></i>
        {{'ui.runningTasks'|translate}}: <b>{{realTimeDataService.runningOperationsCount}}</b>
      </a>
      <a mat-list-item routerLinkActive="active-link" [routerLink]="['/status']">
        <i class="la la-server la-fw"></i>
        {{'ui.status'|translate}}
      </a>

    </mat-nav-list>

  </mat-drawer>

  <mat-drawer-content #drawerContent>

    <div [style.background-image]="'url(\'./assets/images/backgrounds/' + backgroundImage + '\')'" class="main-layout">

      <mat-toolbar color="primary">

        <span class="page-title">
          <a *ngIf="exitRoute" mat-icon-button [routerLink]="exitRoute" style="padding: 8px 4px !important;">
            <i class="la la-angle-left la-fw"></i>
          </a>
          <ng-content select="page-title"></ng-content>
        </span>

        <span class="library-tools">
          <ng-content select="library-tools"></ng-content>
        </span>

        <span class="drawer-toggle">
          <button mat-icon-button (click)="drawer.toggle()">
            <i class="la la-bars la-fw"></i>
          </button>
        </span>

      </mat-toolbar>

      <div class="page-content" #pageContentDiv>
        <ng-content select="page-content"></ng-content>
      </div>

    </div>

  </mat-drawer-content>

</mat-drawer-container>


