<app-library-explorer2-layout (scrolledToBottom)="onScrolledToBottom()"
                              [backgroundImage]="profileSettingsService.movieLibraryBackgroundImage"
                              [(searchText)]="stateService.contentItemsSearchText"
                              [availableBasePaths]="stateService.basePaths"
                              [(basePath)]="stateService.currentBasePath"
                              [availableViews]="stateService.contentItemsViews"
                              [(views)]="stateService.contentItemsSelectedViews"
                              [availableInitials]="stateService.contentItemsInitials"
                              [(initial)]="stateService.contentItemsSearchText"
                              exitRoute="/home">

  <page-title>{{'ui.books'|translate}}</page-title>

  <page-content>

    <div *ngIf="stateService.contentItems.length==0">
      <h1>{{'ui.nothingToDisplay'|translate}}</h1>
    </div>

    <div *ngFor="let authorName of stateService.contentItems | groupByImpure:'authorName' | keys">

      <h1 class=" author-name">{{authorName}}</h1>

      <div class="items">

        <app-item-layout *ngFor="let item of stateService.contentItems | filterBy: ['authorName']: authorName "
                         [layout]="stateService.currentDisplayMode"
                         [item]="item">

          <item-primary-actions>

            <button mat-button color="accent" [routerLink]="['/books', item.id]">
              <i class="la la-play la-fw"></i> {{'actions.read'|translate}}
            </button>

            <mat-form-field>
              <mat-label>{{'actions.publishTo'|translate}}</mat-label>
              <mat-select multiple [compareWith]="compareToDevices" [ngModel]="item.toDevices">
                <mat-option (onSelectionChange)="onToDeviceSelectionChange($event,item.id)" *ngFor="let device of profileSettingsService.profile.devices;" [value]="device.id">{{device.name}} ({{device.pushMethod}})</mat-option>
              </mat-select>
            </mat-form-field>

          </item-primary-actions>

          <item-secondary-actions>
            <button mat-button type="button" (click)="onMarkAsButtonClick(item.id, item.progressPercent < 0.99)">
              <i class="la la-check la-fw"></i> {{'actions.markAs'|translate}}
              {{(item.progressPercent >=0.99 ? 'ui.notRead' : 'ui.read')|translate}}
            </button>
            <button mat-button type="button" (click)="onForceInspectButtonClick(item.id)">
              <i class="la la-sync la-fw"></i> {{'actions.inspect'|translate}}
            </button>
          </item-secondary-actions>

        </app-item-layout>

      </div>

    </div>


  </page-content>

</app-library-explorer2-layout>
