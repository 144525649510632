<app-main-layout [backgroundImage]="profileSettingsService.defaultBackgroundImage"
                 exitRoute="/home">

  <page-title>Scheduled tasks</page-title>

  <page-content>
    <iframe *ngIf="hangfireUrl" style="width:100%; height: 100%; display:block;" [src]="hangfireUrl | unsafeUrl" frameborder="0" scrolling="yes"></iframe>
  </page-content>

</app-main-layout>
