<app-main-layout [backgroundImage]="profileSettingsService.defaultBackgroundImage"
                 exitRoute="/home">

  <page-title>
    {{'ui.configuration'|translate}}
  </page-title>

  <library-tools>

    <button mat-button (click)="onSaveButtonClick()"><i class="la la-save la-fw"></i> {{'actions.save'|translate}}</button>
    <button mat-button (click)="onCancelButtonClick()"><i class="la la-undo la-fw"></i> {{'actions.cancel'|translate}}</button>

  </library-tools>

  <page-content>

    <div class="layout">

      <mat-tab-group *ngIf="configurationService.loaded">

        <mat-tab label="{{'ui.paths'|translate}}" class="items">

          <div class="items">

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.books'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>

                <app-string-list-editor [items]="configurationService.configuration.paths.bookLibraryFolderPaths"
                                        pattern='([a-zA-Z]:(\\[^<>:"\/\\|?*]+)+)|((\/[^\/]+)+)'
                                        minlength="6"
                                        itemtitle="Path"></app-string-list-editor>
              </mat-card-content>
            </mat-card>

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.comics'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-form-field>
                  <mat-label>{{'ui.blackhole'|translate}}</mat-label>
                  <input type="text" [(ngModel)]="configurationService.configuration.paths.comicLibraryBlackHoleFolderPath" matInput />
                </mat-form-field>
                <app-string-list-editor [items]="configurationService.configuration.paths.comicLibraryFolderPaths"
                                        pattern='([a-zA-Z]:(\\[^<>:"\/\\|?*]+)+)|((\/[^\/]+)+)'
                                        minlength="6"
                                        itemtitle="Path"></app-string-list-editor>
              </mat-card-content>
            </mat-card>

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.movies'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-form-field>
                  <mat-label>{{'ui.blackhole'|translate}}</mat-label>
                  <input type="text" [(ngModel)]="configurationService.configuration.paths.movieLibraryBlackHoleFolderPath" matInput />
                </mat-form-field>
                <app-string-list-editor [items]="configurationService.configuration.paths.movieLibraryFolderPaths"
                                        pattern='([a-zA-Z]:(\\[^<>:"\/\\|?*]+)+)|((\/[^\/]+)+)'
                                        minlength="6"
                                        itemtitle="Path"></app-string-list-editor>
              </mat-card-content>
            </mat-card>

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.music'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <app-string-list-editor [items]="configurationService.configuration.paths.musicLibraryFolderPaths"
                                        pattern='([a-zA-Z]:(\\[^<>:"\/\\|?*]+)+)|((\/[^\/]+)+)'
                                        minlength="6"
                                        itemtitle="Path"></app-string-list-editor>
              </mat-card-content>
            </mat-card>

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.shows'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <app-string-list-editor [items]="configurationService.configuration.paths.showLibraryFolderPaths"
                                        pattern='([a-zA-Z]:(\\[^<>:"\/\\|?*]+)+)|((\/[^\/]+)+)'
                                        minlength="6"
                                        itemtitle="Path"></app-string-list-editor>
              </mat-card-content>
            </mat-card>

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.fileManager'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <app-string-list-editor [items]="configurationService.configuration.paths.fileManagerRootPaths"
                                        pattern='([a-zA-Z]:(\\[^<>:"\/\\|?*]+)+)|((\/[^\/]+)+)'
                                        minlength="6"
                                        itemtitle="Path"></app-string-list-editor>
              </mat-card-content>
            </mat-card>

            </div>

        </mat-tab>

        <mat-tab label="{{'ui.streaming'|translate}}" class="items">

          <div class="items">

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.audio'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-form-field>
                  <mat-label>{{'ui.streamAnalysisMode'|translate}}</mat-label>
                  <mat-select [(value)]="configurationService.configuration.audioStreaming.streamAnalysisMode">
                    <mat-option *ngFor="let item of streamAnalysisModes | keyvalue " [(value)]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{'ui.staticConversionMode'|translate}}</mat-label>
                  <mat-select [(value)]="configurationService.configuration.audioStreaming.staticStreamConversionMode">
                    <mat-option *ngFor="let item of staticStreamConversionModes | keyvalue " [(value)]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-slide-toggle [(ngModel)]="configurationService.configuration.audioStreaming.sendChunked">
                  {{'ui.sendChunked'|translate}}
                </mat-slide-toggle>
              </mat-card-content>
            </mat-card>

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.video'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-form-field>
                  <mat-label>{{'ui.streamAnalysisMode'|translate}}</mat-label>
                  <mat-select [(value)]="configurationService.configuration.videoStreaming.streamAnalysisMode">
                    <mat-option *ngFor="let item of streamAnalysisModes | keyvalue " [(value)]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{'ui.staticConversionMode'|translate}}</mat-label>
                  <mat-select [(value)]="configurationService.configuration.videoStreaming.staticStreamConversionMode">
                    <mat-option *ngFor="let item of staticStreamConversionModes | keyvalue " [(value)]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-slide-toggle [(ngModel)]="configurationService.configuration.videoStreaming.sendChunked">
                  {{'ui.sendChunked'|translate}}
                </mat-slide-toggle>
              </mat-card-content>
            </mat-card>

            </div>

        </mat-tab>

        <mat-tab label="{{'ui.mainentance'|translate}}" class="items">

          <div class="items">

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.comics'|translate}}</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <mat-label>{{'ui.seriesNamingTemplate'|translate}}</mat-label>
                <ngx-monaco-editor style="height: 300px" [options]="editorOptions" [(ngModel)]="configurationService.configuration.maintenance.comicSeriesNaming"></ngx-monaco-editor>

                <mat-label>{{'ui.issueNamingTemplate'|translate}}</mat-label>
                <ngx-monaco-editor style="height: 300px" [options]="editorOptions" [(ngModel)]="configurationService.configuration.maintenance.comicIssueNaming"></ngx-monaco-editor>

              </mat-card-content>
            </mat-card>

            </div>

        </mat-tab>

        <mat-tab label="{{'ui.integrations'|translate}}" class="items">

          <div class="items">

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.comicVine'|translate}}</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <mat-form-field>
                  <mat-label>{{'ui.apiKey'|translate}}</mat-label>
                  <input type="text" [(ngModel)]="configurationService.configuration.integrations.comicVine.apiKey" matInput />
                </mat-form-field>

              </mat-card-content>
            </mat-card>

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.theMovieDB'|translate}}</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <mat-form-field>
                  <mat-label>{{'ui.apiKey'|translate}}</mat-label>
                  <input type="text" [(ngModel)]="configurationService.configuration.integrations.tmdb.apiKey" matInput />
                </mat-form-field>

              </mat-card-content>
            </mat-card>

            <mat-card class="editor-block">
              <mat-card-header>
                <mat-card-title>{{'ui.pushBullet'|translate}}</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <mat-form-field>
                  <mat-label>{{'ui.apiKey'|translate}}</mat-label>
                  <input type="text" [(ngModel)]="configurationService.configuration.integrations.pushbullet.apiKey" matInput />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{'ui.broadcastTo'|translate}}</mat-label>
                  <input type="email" [(ngModel)]="configurationService.configuration.integrations.pushbullet.broadcastTo" matInput />
                </mat-form-field>

              </mat-card-content>
            </mat-card>

            <mat-card class="editor-block">

              <mat-card-header>
                <mat-card-title>{{'ui.embeddedApps'|translate}}</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <div *ngFor="let item of configurationService.configuration.integrations.embeddedApps | keyvalue;">
                  <span style="word-break: break-all;">
                    <b>{{ item.value }}</b>
                    ({{ item.key }})
                  </span>
                  <button mat-icon-button (click)="onRemoveEmbeddedAppButtonClick(item.key)">
                    <i class="la la-trash la-fw"></i>
                  </button>
                  <button mat-icon-button (click)="onEditEmbeddedAppButtonClick(item.key)">
                    <i class="la la-edit la-fw"></i>
                  </button>
                </div>

                <mat-card>
                  <mat-card-content>
                    <mat-form-field>
                      <mat-label>{{'ui.name'|translate}}</mat-label>
                      <input required type="text" [(ngModel)]="newEmbeddedApp.name" matInput>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>{{'ui.address'|translate}}</mat-label>
                      <input required type="url" [(ngModel)]="newEmbeddedApp.url" matInput>
                    </mat-form-field>

                    <mat-card-actions>
                      <button [disabled]="!newEmbeddedApp.name || !newEmbeddedApp.url" mat-button (click)="onSaveEmbeddedAppButtonClick();">
                        <i class="la la-save la-fw"></i> {{'actions.save'|translate}}
                      </button>
                    </mat-card-actions>

                  </mat-card-content>
                </mat-card>

              </mat-card-content>
            </mat-card>

            </div>

        </mat-tab>

      </mat-tab-group>

    </div>

  </page-content>


</app-main-layout>
