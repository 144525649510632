<div class="audio-tag-wrapper" [class.hidden]="!currentTrack">

  <div *ngIf="currentTrack">
    <span class="title">{{currentTrack.title}}</span>
    <span *ngIf="currentTrack.number"> {{'ui.track'|translate}} <b>#{{currentTrack.number}}</b> </span>
    <span *ngIf="currentTrack.albumName" class="album"> {{'ui.of'|translate}} <b>{{currentTrack.albumName}}</b> </span>
    <span *ngIf="currentTrack.artistName" class="artist"> {{'ui.by'|translate}} <b>{{currentTrack.artistName}}</b> </span>
    <span *ngIf="currentTrack.year" class="year"> ({{currentTrack.year}}) </span>
  </div>

  <audio #audioTag preload="auto" controls></audio>

</div>
