<h2 mat-dialog-title>
  <i class="la la-search la-fw"></i> {{'ui.searchOnComicVine'|translate}}
</h2>

<div mat-dialog-content>

  <form #searchForm="ngForm" (ngSubmit)="onSearchFormSubmit()">

    <mat-form-field style="width:100%">
      <input matInput placeholder="text to search" name="searchText" [value]="model.searchText">
      <button matSuffix mat-icon-button type="submit" #submitButton>
        <i class="la la-search la-fw"></i>
      </button>
    </mat-form-field>

  </form>

  <div *ngIf="model.secondaryContent" class="mat-body-1">{{model.secondaryContent}}</div>

  <div *ngIf="this.items.length" class="search-results">
    <div class="search-result" *ngFor="let item of this.items">
      <img src="{{item.image.medium_url}}">
      <div class="metadata">
        <table>
          <tr>
            <td>{{'ui.name'|translate}}:</td>
            <td><b>{{item.name}}</b></td>
          </tr>
          <tr>
            <td>{{'ui.year'|translate}}:</td>
            <td><b>{{item.start_year}}</b></td>
          </tr>
          <tr>
            <td>{{'ui.issuesCount'|translate}}:</td>
            <td><b>{{item.count_of_issues}}</b></td>
          </tr>
        </table>
        <button mat-button type="button" [mat-dialog-close]="item">
          <i class="la la-check la-fw"></i> {{'actions.chooseThis'|translate}}
        </button>
      </div>
    </div>
  </div>

</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{'actions.close'|translate}}</button>
</div>
