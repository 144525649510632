import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
import filesize from 'filesize';
class FileSizePipe {
  static transformOne(value, options) {
    return filesize(value, options);
  }
  transform(value, options) {
    if (Array.isArray(value)) {
      return value.map(val => FileSizePipe.transformOne(val, options));
    }
    return FileSizePipe.transformOne(value, options);
  }
}
FileSizePipe.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.7",
  ngImport: i0,
  type: FileSizePipe,
  deps: [],
  target: i0.ɵɵFactoryTarget.Pipe
});
FileSizePipe.ɵpipe = i0.ɵɵngDeclarePipe({
  minVersion: "14.0.0",
  version: "14.2.7",
  ngImport: i0,
  type: FileSizePipe,
  name: "filesize"
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.7",
  ngImport: i0,
  type: FileSizePipe,
  decorators: [{
    type: Pipe,
    args: [{
      name: 'filesize'
    }]
  }]
});
class NgxFilesizeModule {}
NgxFilesizeModule.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.7",
  ngImport: i0,
  type: NgxFilesizeModule,
  deps: [],
  target: i0.ɵɵFactoryTarget.NgModule
});
NgxFilesizeModule.ɵmod = i0.ɵɵngDeclareNgModule({
  minVersion: "14.0.0",
  version: "14.2.7",
  ngImport: i0,
  type: NgxFilesizeModule,
  declarations: [FileSizePipe],
  exports: [FileSizePipe]
});
NgxFilesizeModule.ɵinj = i0.ɵɵngDeclareInjector({
  minVersion: "12.0.0",
  version: "14.2.7",
  ngImport: i0,
  type: NgxFilesizeModule
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.7",
  ngImport: i0,
  type: NgxFilesizeModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [FileSizePipe],
      exports: [FileSizePipe]
    }]
  }]
});

/*
 * Public API Surface of ngx-filesize
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FileSizePipe, NgxFilesizeModule };
