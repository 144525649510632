/* src/app/pages/comic-viewer/comic-viewer.component.scss */
mat-drawer-container {
  overflow: hidden;
}
mat-drawer {
  width: 200px;
}
mat-drawer mat-list-item {
  height: auto;
  max-width: 170px;
  padding: 4px;
  cursor: pointer;
}
mat-drawer mat-list-item img {
  max-width: 162px;
}
.viewer-root {
  position: absolute;
  width: 100%;
  overflow: hidden;
}
.control {
  position: fixed;
  color: rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.control .la {
  width: 100%;
  height: 100%;
  display: block;
}
.control.primary {
  bottom: 30vh;
  font-size: 5vh;
  width: 8vh;
  height: 8vh;
}
.control.primary.previous-page {
  left: 4px;
}
.control.primary.next-page {
  right: 4px;
}
.control.current-page {
  left: 4px;
  bottom: 4px;
}
.control.exit {
  right: 4px;
  bottom: 4px;
  font-size: 3vh;
  width: 5vh;
  height: 5vh;
}
.control.more-actions {
  right: 4px;
  top: 4px;
}
/*# sourceMappingURL=comic-viewer.component.css.map */
