<app-library-explorer2-layout (scrolledToBottom)="onScrolledToBottom()"
                              [backgroundImage]="profileSettingsService.movieLibraryBackgroundImage"
                              [(searchText)]="stateService.contentItemsSearchText"
                              [availableBasePaths]="stateService.basePaths"
                              [(basePath)]="stateService.currentBasePath"
                              [availableViews]="stateService.contentItemsViews"
                              [(views)]="stateService.contentItemsSelectedViews"
                              [availableInitials]="stateService.contentItemsInitials"
                              [(initial)]="stateService.contentItemsSearchText"
                              exitRoute="/home">

  <page-title>{{'ui.movies'|translate}}</page-title>

  <page-content>

    <div class="" *ngIf="stateService.contentItems.length==0">
      <h1>{{'ui.nothingToDisplay'|translate}}</h1>
    </div>

    <div class="items">

      <app-item-layout *ngFor="let item of stateService.contentItems" [layout]="['card']" [item]="item">

        <item-primary-actions>
          <button mat-button (click)="onSearchTMDBButtonClick(item.id)">
            <i class="la la-search la-fw"></i> {{'actions.searchTmdb'|translate}}
          </button>
          <a *ngIf="item.tmdbId" mat-button href="https://www.themoviedb.org/movie/{{item.tmdbId}}" target="_blank">
            <i class="la la-external-link-alt la-fw"></i> {{'actions.openOnTmdb'|translate}}
          </a>
        </item-primary-actions>

        <item-secondary-actions>
          <button mat-button type="button" (click)="onMarkAsButtonClick(item.id, item.progressPercent < 0.99)">
            <i class="la la-check la-fw"></i> {{'actions.markAs'|translate}}
            {{(item.progressPercent >=0.99 ? 'ui.notSeen' : 'ui.seen')|translate}}
          </button>
          <button mat-button type="button" (click)="onForceInspectButtonClick(item.id)">
            <i class="la la-sync la-fw"></i> {{'actions.inspect'|translate}}
          </button>
          <button mat-button color="warn" type="button" (click)="onRemoveButtonClick(item.id)">
            <i class="la la-trash la-fw"></i> {{'actions.remove'|translate}}
          </button>

          <mat-form-field>
            <mat-label>{{'actions.publishTo'|translate}}</mat-label>
            <mat-select multiple [compareWith]="compareToDevices" [ngModel]="item.toDevices">
              <mat-option (onSelectionChange)="onToDeviceSelectionChange($event,item.id)" *ngFor="let device of profileSettingsService.profile.devices;" [value]="device.id">{{device.name}} ({{device.pushMethod}})</mat-option>
            </mat-select>
          </mat-form-field>

        </item-secondary-actions>

      </app-item-layout>

    </div>

  </page-content>

  <page-additional-filters>

    <h2>{{'ui.videoFeatures'|translate}}</h2>

    <mat-form-field>
      <mat-label>{{'ui.videoResolution'|translate}}</mat-label>
      <mat-select [value]="videoResolution" (selectionChange)="onVideoResolutionChange($event)">
        <mat-option [value]="undefined">{{'ui.all'|translate}}</mat-option>
        <mat-option *ngFor="let videoResolution of videoResolutions" [value]="videoResolution">
          {{videoResolution}}
        </mat-option>
      </mat-select>
    </mat-form-field>


  </page-additional-filters>

</app-library-explorer2-layout>
