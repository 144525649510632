<app-main-layout [backgroundImage]="profileSettingsService.defaultBackgroundImage"
                 exitRoute="/home">

  <page-title>
    {{'ui.receivedMessages'|translate}}
  </page-title>

  <library-tools>

    <button mat-button (click)="onDismissAllButtonClick()"><i class="la la-check-double la-fw"></i> {{'actions.dismissAll'|translate}}</button>

  </library-tools>

  <page-content>

      <table  *ngIf="messages.length>0" mat-table [dataSource]="messages">

        <ng-container matColumnDef="creation">
          <th mat-header-cell *matHeaderCellDef> {{'ui.date'|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.creation | date : 'yyyy-MM-dd HH:mm:ss'}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> {{'ui.type'|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.typeName}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> {{'ui.title'|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="content">
          <th mat-header-cell *matHeaderCellDef> {{'ui.content'|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.content}} </td>
        </ng-container>

        <ng-container matColumnDef="additionalPayload">
          <th mat-header-cell *matHeaderCellDef> {{'ui.more'|translate}}... </th>
          <td mat-cell *matCellDef="let element">{{ element.additionalPayload }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div class="" *ngIf="messages.length==0">
        <h1>{{'ui.nothingToDisplay'|translate}}</h1>
      </div>

  </page-content>


</app-main-layout>
