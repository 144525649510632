<app-library-explorer2-layout (searchTextChange)="onSearchTextChanged($event)"
                              [searchText]="stateService.searchText"
                              [backgroundImage]="profileSettingsService.musicLibraryBackgroundImage"
                              [availableViews]="stateService.availableViews"
                              [views]="stateService.selectedViews"
                              (viewsChange)="onViewsChange($event)"
                              exitRoute="/home">

  <page-title>{{'ui.music'|translate}}</page-title>

  <page-content>

    <div class="layout">

      <div *ngIf="!screenDetectionService.isMobile" class="tags-container">

        <div class="title-bar">
          <h1>{{'ui.tags'|translate}}</h1>
          <button *ngIf="hasSelectedTags" mat-icon-button (click)="onClearTagsButtonClick()">
            <i class="la la-eraser la-fw"></i>
          </button>
        </div>

        <div class="tags">

          <div *ngFor="let artistName of this.artistNames | filterByInitialOrPart: stateService.searchText | groupByInitial ">

            <h3>{{artistName.key}}</h3>

            <span *ngFor="let name of artistName.value; let i = index">

              <span>

                <button mat-stroked-button type="button" [class.mat-primary]="name==stateService.selectedArtistName"
                        (click)="onArtistNameClick(name, true)">
                  {{name}}
                </button>

                <!--
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <i class="la la-ellipsis-v la-fw" ></i>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item>
                    <i class="la la-eraser la-fw" ></i>
                    <span>Redial</span>
                  </button>
                </mat-menu>
                -->

              </span>

              <div class="albums" *ngIf="name==stateService.selectedArtistName">
                <button mat-stroked-button type="button" *ngFor="let albumName of this.albumNames"
                        [class.mat-primary]="albumName==stateService.selectedAlbumName"
                        (click)="onAlbumNameClick(name, albumName)">
                  {{albumName}}
                </button>
              </div>

            </span>

          </div>

        </div>

      </div>

      <div class="tracks-container">

        <div class="title-bar">
          <h1 *ngIf="!this.stateService.selectedArtistName">{{musicPlayerStateService.tracks.length}} {{'ui.randomTracks'|translate}}</h1>
          <h1 *ngIf="this.stateService.selectedArtistName && !this.stateService.selectedAlbumName">
            {{this.stateService.selectedArtistName}}
          </h1>
          <h1 *ngIf="this.stateService.selectedAlbumName">
            {{this.stateService.selectedAlbumName}} by
            {{this.stateService.selectedArtistName}}
          </h1>
          <button mat-icon-button (click)="onGetNewTracksButtonClick()">
            <i class="la la-retweet la-fw"></i>
          </button>
        </div>

        <div class="tracks">
          <div *ngFor="let track of musicPlayerStateService.tracks" class="track">

            <div class="now-playing" *ngIf="track.id==musicPlayerStateService.currentTrackId">
              <i class="la la-broadcast-tower la-fw blink"></i>
            </div>

            <div class="meta">

              <div class="title">
                {{track.title}} <!--<button mat-icon-button (click)="onSearchLyricsButtonClick(track)">
                  <i class="la la-book-open la-fw"></i>
                </button>-->
              </div>

              <div class="tags">
                <span *ngIf="track.number"> {{'ui.track'|translate}} <b>#{{track.number}}</b> </span>
                <span *ngIf="track.albumName" class="album">
                  {{'ui.of'|translate}} <span class="link" (click)="onAlbumNameClick(track.artistName, track.albumName)">{{track.albumName}}</span>
                </span>
                <span *ngIf="track.artistName" class="artist">
                  {{'ui.by'|translate}} <span class="link" (click)="onArtistNameClick(track.artistName, true)">{{track.artistName}}</span>
                </span>
                <span *ngIf="track.year" class="year"> ({{track.year}}) </span>
              </div>

            </div>
            <div class="actions">

              <button mat-icon-button (click)="onPlayToggleButtonClick(track.id)">
                <i class="la la-pause la-fw"
                   *ngIf="musicPlayerStateService.stateModel.status == MusicPlayerStatus.playing && track.id==musicPlayerStateService.currentTrackId"></i>
                <i class="la la-play la-fw"
                   *ngIf="musicPlayerStateService.stateModel.status != MusicPlayerStatus.playing || track.id!=musicPlayerStateService.currentTrackId"></i>
              </button>
              <button [class.mat-primary]="track.rating==1" mat-icon-button
                      (click)="onRateTrackButtonClick(track.id, 1)">
                <i class="la la-thumbs-up la-fw"></i>
              </button>
              <button [class.mat-primary]="track.rating==-1" mat-icon-button
                      (click)="onRateTrackButtonClick(track.id, -1)">
                <i class="la la-thumbs-down la-fw"></i>
              </button>

            </div>
          </div>
        </div>
      </div>

    </div>

  </page-content>

  <page-additional-filters>

    <div class="tags-container" *ngIf="screenDetectionService.isMobile">

      <div>
        <h2>{{'ui.tags'|translate}}</h2>
        <button *ngIf="hasSelectedTags" mat-icon-button (click)="onClearTagsButtonClick()">
          <i class="la la-eraser la-fw"></i>
        </button>
      </div>

      <div class="tags">

        <div *ngFor="let artistName of this.artistNames | filterByInitialOrPart: stateService.searchText | groupByInitial ">

          <h3>{{artistName.key}}</h3>

          <div *ngFor="let name of artistName.value; let i = index">

            <span>

              <button mat-stroked-button type="button" [class.mat-primary]="name==stateService.selectedArtistName"
                      (click)="onArtistNameClick(name, true)">
                {{name}}
              </button>

            </span>

            <div class="albums" *ngIf="name==stateService.selectedArtistName">
              <button mat-stroked-button type="button" *ngFor="let albumName of this.albumNames"
                      [class.mat-primary]="albumName==stateService.selectedAlbumName"
                      (click)="onAlbumNameClick(name, albumName)">
                {{albumName}}
              </button>
            </div>

          </div>

        </div>

      </div>
    </div>

  </page-additional-filters>

</app-library-explorer2-layout>
