<app-main-layout [backgroundImage]="profileSettingsService.defaultBackgroundImage"
                 exitRoute="/home">

  <page-title>
    {{'ui.fileManager'|translate}}
  </page-title>

  <library-tools>

    <div *ngIf="stateService.clipboardItem.follow">
      <i class="la la-cut la-fw" *ngIf="stateService.clipboardMode=='cut'"></i>
      <i class="la la-copy la-fw" *ngIf="stateService.clipboardMode=='copy'"></i>
      <span> {{stateService.clipboardItem.name}}</span>
      <button mat-icon-button (click)="onClearClipboardButtonClick()"><i class="la la-times la-fw" ></i></button>
    </div>

  </library-tools>

  <page-content>

    <div class="layout">

      <div *ngFor="let pane of stateService.panes;let i = index"
           class="pane ">

        <div class="title-bar">
          <h1>{{ pane.name}}</h1>
          <div class="toolbar">
            <button *ngIf="pane.type!='Root' && stateService.clipboardItem.follow" mat-button (click)="onPasteButtonClick(i)">
              <i class="la la-paste la-fw" ></i> Paste
            </button>
          </div>
          <button mat-icon-button (click)="onRefreshPaneButtonClick(i)">
            <i class="la la-retweet la-fw" ></i>
          </button>
        </div>

        <div class="breadcrumb">
          <span *ngFor="let item of pane.breadcrumbParts | keyvalue">
            <button mat-stroked-button (click)="onBreadCrumbButtonClick(i, item.key)">{{item.value}}</button>
          </span>
        </div>

        <div class="file-system-object" *ngFor="let item of pane.children">
          <div class="metadata">
            <button  style="overflow:hidden" mat-button *ngIf="item.type!='File'" (click)="onFileSystemObjectButtonClick(i, item)">
              <i class="la la-folder-open la-fw file-type-icon" ></i> {{item.name}}
            </button>
            <a mat-button style="overflow:hidden" *ngIf="item.type=='File'" [href]="globalStateService.serverUrl +  '/' + item.contentUrl | unsafeUrl" target="_blank">
              <i class="la la-file la-fw file-type-icon">
                <span class="extension">{{item.extension}}</span>
              </i> {{item.name}}
            </a>
            <div *ngIf="item.type=='File'" class="size">{{item.size | filesize}}</div>
            <div *ngIf="item.type!='File'" class="size">{{item.size}}</div>
          </div>
          <div class="tools">
            <button *ngIf="item.type!='Root'" (click)="onCopyButtonClick(item)" mat-icon-button>
              <i class="la la-copy la-fw" ></i>
            </button>
            <button *ngIf="item.type!='Root'" (click)="onCutButtonClick(item)" mat-icon-button>
              <i class="la la-cut la-fw" ></i>
            </button>
            <button *ngIf="item.type!='Root'" (click)="onDeleteButtonClick(i, item)" mat-icon-button>
              <i class="la la-trash la-fw" ></i>
            </button>
          </div>
        </div>

      </div>

    </div>

  </page-content>


</app-main-layout>
