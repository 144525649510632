<mat-drawer-container class="full-vh">

  <mat-drawer #drawer [position]="'start'" [mode]="'push'">

    <mat-list>
      <mat-list-item *ngFor="let issuePageNumber of issuePageNumbers; let i = index"
         (click)="onJumpToPageButtonClick(issuePageNumber)">
        <div>
          <div>
            <img defaultImage="assets/images/not-found.png" [lazyLoad]="getPageThumbnailUrl(issuePageNumber)" />
          </div>
          <div>
            {{'ui.page'|translate}} {{issuePageNumber}}
          </div>
        </div>
      </mat-list-item>
    </mat-list>

  </mat-drawer>

  <mat-drawer-content #drawerContent>

    <div class="viewer-root full-vh">

      <pinch-zoom #pinchZoom autoHeight="false" style="height:100%" double-tap="true">
        <img [src]="currentPageImageUrl" />
      </pinch-zoom>

      <button *ngIf="pageNumber>1" type="button" mat-icon-button class="control primary previous-page" #previousPage
              (click)="onJumpToPageButtonClick(pageNumber-1)">
        <i class="la la-chevron-left la-fw"></i>
      </button>

      <button *ngIf="pageNumber<issueLength" type="button" mat-icon-button class="control primary next-page" #nextPage
              (click)="onJumpToPageButtonClick(pageNumber+1)">
        <i class="la la-chevron-right la-fw"></i>
      </button>

      <button *ngIf="pageNumber==issueLength" type="button" mat-icon-button class="control primary next-page" #nextPage
              (click)="onJumpToNextIssueButtonClick()">
        <i class="la la-step-forward la-fw"></i>
      </button>

      <button (click)="drawer.toggle()" *ngIf="issueLength" type="button" mat-button class="control current-page">
        {{pageNumber}} / {{issueLength}}
      </button>

      <a mat-icon-button [routerLink]="['/comic-library/series', seriesId]" class="control exit">
        <i class="la la-sign-out-alt la-fw"></i>
      </a>

      <button *ngIf="!series?.blackHole" class="control more-actions" mat-icon-button [matMenuTriggerFor]="menu"><i class="las la-ellipsis-h"></i></button>
      <mat-menu #menu="matMenu">
        <button *ngIf="!series?.blackHole" mat-button color="warn" type="button" (click)="onRemoveSeriesButtonClick()">
          <i class="la la-trash la-fw"></i> {{'actions.removeSeries'|translate}}
        </button>
      </mat-menu>

    </div>

  </mat-drawer-content>

</mat-drawer-container>

<div style="display:none">
  <img [src]="previousPageImageUrl1" />
  <img [src]="nextPageImageUrl1" />
  <img [src]="nextPageImageUrl2" />
</div>
