<app-main-layout [backgroundImage]="profileSettingsService.defaultBackgroundImage"
                 exitRoute="/home">

  <page-title>{{'ui.proposedDownloads'|translate}}</page-title>

  <page-content>

    <div class="" *ngIf="stateService.items.length==0">
      <h1>{{'ui.nothingToDisplay'|translate}}</h1>
    </div>

    <div class="items">

      <app-item-layout *ngFor="let item of stateService.items" [layout]="['card']" [item]="item">

        <item-primary-actions>
          <a mat-button target="_blank" [href]="item.detailsLink">
            <i class="la la-external-link-alt la-fw"></i> {{'actions.openOrigin'|translate}}
          </a>
          <button mat-button type="button" (click)="onHideButtonClick(item.id)">
            <i class="la la-eye-slash la-fw"></i> {{'actions.hide'|translate}}
          </button>
          <button *ngIf="item.downloadUrls.length" mat-button color="accent"
                  type="button" (click)="onCopyDownloadUrlsButtonClick(item.downloadUrls)">
            <i class="la la-copy la-fw"></i> {{'actions.copyDownloadUrls'|translate:{count:item.downloadUrls.length} }}
          </button>
          <div *ngIf="item.directDownloadDownloaded" class="blink">
            <i class="las la-download  la-fw"></i> {{'ui.directDownloadDownloaded'|translate }} '{{item.directDownloadFileName}}'
          </div>
        </item-primary-actions>

        <item-secondary-actions>
        </item-secondary-actions>

      </app-item-layout>

    </div>

  </page-content>

</app-main-layout>
